import { FC } from 'react'
import { Link } from 'react-router-dom'
import Icons from '@helpers/IconsHelper'
import './TopHeader.css'

const TopHeader: FC = () => {

    return (
        <div className='top-header-container d-flex-end'>
            <div className="containerr top-container w-100 mx5">
                <div className="row d-flex-end mx-3">
                    <div className="col-12 text-end">
                        <Icons.FaFacebookF className='mx-1' />
                        <Icons.RiInstagramFill className='mx-1' />
                        <Icons.FaYoutube className='mx-1' />

                        <Link to='/' className='nav-link d-inline px-2'>
                            Blog
                        </Link>

                        <Link to='/' className='nav-link d-inline px-2'>
                            Professionals
                        </Link>

                        <Link to='/' className='nav-link d-inline px-2'>
                            Career
                        </Link>

                        <Link to='/' className='nav-link d-inline px-2'>
                            Contact
                        </Link>

                        {/* <Link to='/' className='nav-link d-inline px-2'>
                            <Icons.BsSearch className="top-header-icon me" />
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeader