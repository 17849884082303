import { allTheMomentsBg } from '@helpers/Index'
import { AutresMoments, Cadeaux, GrandesOccasions, Occasions } from '../../../../data/moments'
import { MomentItemProps } from '@helpers/Props'
import { useEffect } from 'react'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { handleScrollToTop } from '@helpers/Helpers'
import ShopHeaderContainer from '@components/Shop/components/ShopHeaderContainer/ShopHeaderContainer'
import Layout from '@layouts/Layout'
import MomentCard from '@components/MomentCard/MomentCard'
import './AllTheMoments.css'

const AllTheMoments: React.FC = () => {

    const { windowWidth } = useWindowWidthContext()
    useEffect(() => {
        handleScrollToTop()
    }, [])


    return (
        <Layout>
            <ShopHeaderContainer
                title='Partagez le bonheur!'
                background={allTheMomentsBg}
                haveBreadCrumbs={false} url=''
                titleStyle={`clr-blue fs-${windowWidth > 578 ? '60' : '25'}px`}
                haveImage={false}
                haveSecondUrl={false} />

            <div className="container mt-5rem">
                <div className="row">
                    <p className="clr-dark ff-ubuntu-reg fs-18px">
                        Un cadeau Leonidas? Cela fait toujours plaisir. Et il y a toujours une bonne raison de faire plaisir à ceux qu'on aime. Faites votre choix parmi nos chocolats et autres gourmandises. La surprise commence dès l’emballage. Offrir des chocolats Leonidas, c’est partager des moments de bonheur.
                    </p>
                </div>

                {/* Grandes Occasions Start */}
                <div className="row mt-3rem">
                    <h1 className='clr-blue ff-ubuntu-m fw-600 fs-46px'>Grandes occasions</h1>
                    {GrandesOccasions.map((item: MomentItemProps) => (
                        <MomentCard key={item.slug} item={item} />
                    ))}
                </div>
                {/* Grandes Occasions End */}

                {/* Occasions Start */}
                <div className="row">
                    <h1 className='clr-blue ff-ubuntu-m fw-600 fs-46px'>Occasions</h1>
                    {Occasions.map((item: MomentItemProps) => (
                        <MomentCard key={item.slug} item={item} />
                    ))}
                </div>
                {/* Occasions End */}

                {/* Cadeaux Start */}
                <div className="row">
                    <h1 className='clr-blue ff-ubuntu-m fw-600 fs-46px'>Cadeaux</h1>
                    {Cadeaux.map((item: MomentItemProps) => (
                        <MomentCard key={item.slug} item={item} />
                    ))}
                </div>
                {/* Cadeaux End */}

                {/* Autres moments Start */}
                <div className="row">
                    <h1 className='clr-blue ff-ubuntu-m fw-600 fs-46px'>Autres moments</h1>
                    {AutresMoments.map((item: MomentItemProps) => (
                        <MomentCard key={item.slug} item={item} />
                    ))}
                </div>
                {/* Autres moments End */}
            </div>
        </Layout>
    )
}

export default AllTheMoments
