import { paysRegion } from "./Helpers"

export const initLogin = {
    email: "",
    password: "",
}

export const initRegister = {
    nom: "",
    prenom: "",
    telephone: "",
    email: "",
    password: "",
    // confirmPassword: "",
}

export const initUserAccount = {
    nom: "",
    prenom: "",
    telephone: "",
}

export const initUserPassword = {
    password: "",
    newpassword: "",
    confirmPassword: "",
}

export const initOrderAdresse = {
    nom: "",
    prenom: "",
    pays: paysRegion,
    rueInfo: "",
    ville: "",
    region: "",
    codepostal: "",
    telephone: "",
    email: "",
}

export const initResetPassword = {
    password: "",
    confirmPassword: "",
}

export const initOrderByDevis = {
    fullName: process.env.NODE_ENV === 'development' ? 'BEN-SEGHIR MOHAMMED' : '',
    email: process.env.NODE_ENV === 'development' ? 'leonidas.fcpo@gmail.com' : '',
    telephone: process.env.NODE_ENV === 'development' ? '0610101019' : '',
    codepostal: process.env.NODE_ENV === 'development' ? '60000' : '',
    ville: process.env.NODE_ENV === 'development' ? 'Oujda' : '',
    adresse: process.env.NODE_ENV === 'development' ? '1ere étage , Al Maqdis' : '',
    orderNote: process.env.NODE_ENV === 'development' ? '' : '',
}

export const initDevis = {
    fullName: process.env.NODE_ENV === 'development' ? 'BEN-SEGHIR MOHAMMED' : '',
    email: process.env.NODE_ENV === 'development' ? 'leonidas.fcpo@gmail.com' : '',
    telephone: process.env.NODE_ENV === 'development' ? '0610101019' : '',
    date: process.env.NODE_ENV === 'development' ? new Date().toISOString().slice(0, 16) : '',
    description: process.env.NODE_ENV === 'development' ? 'Boujour' : '',
}