import React, { ReactNode } from 'react'
import ShopNavbar from '@components/Shop/components/ShopNavbar/ShopNavbar'
import ShopFooter from '@components/Shop/components/ShopFooter/ShopFooter'
import ShopTopHeader from '@components/Shop/components/ShopTopHeader/ShopTopHeader'
import ShopHeaderContainer from '@components/Shop/components/ShopHeaderContainer/ShopHeaderContainer'
import ShopNewsEmail from '@components/Shop/components/ShopNewsEmail/ShopNewsEmail'
import ShopAboutUs from '@components/Shop/components/ShopAboutUs/ShopAboutUs'

interface ShopLayoutProps {
    children: ReactNode
    containerTitle?: string
    titleStyle?: string
    containerBackground?: string
    containerImage?: string
    containerUrl?: string
    haveSecondUrl: boolean
    haveBreadCrumbs: boolean
    haveImage: boolean
}

const ShopLayout: React.FC<ShopLayoutProps> = ({ children, containerTitle, containerBackground, containerImage, containerUrl, haveSecondUrl, titleStyle = 'clr-light', haveBreadCrumbs, haveImage }) => {
    return (
        <React.Fragment>
            <ShopTopHeader />
            <ShopNavbar />
            <ShopHeaderContainer
                title={`${containerTitle}`}
                background={containerBackground}
                image={containerImage}
                url={`${containerUrl}`}
                haveSecondUrl={haveSecondUrl}
                titleStyle={titleStyle}
                haveBreadCrumbs={haveBreadCrumbs}
                haveImage={haveImage} />
            <main>{children}</main>
            <ShopNewsEmail />
            <ShopAboutUs />
            <ShopFooter />
        </React.Fragment>
    )
}

export default ShopLayout