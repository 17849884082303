import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { OrganisationDevisModalProps } from '@helpers/Props'
import { FormInput, formHelper, loadingHelper, SubmitButton } from '@helpers/Index'
import { useApiRequest } from '@helpers/ApiRequest'
import { FrCustomeErrorNorify, notify } from '@helpers/Toastify'
import { initDevis } from '@helpers/FormikHelper'
import { devisSchema } from '@helpers/YupHelper'
import { FormatDate, JSONHeader, generateRandomKey, orderReferenceGenerator } from '@helpers/Helpers'
import './OrganisationDevisModal.css'

export interface OrganisationDevisProps {
    type: string
}

const OrganisationDevisModal: React.FC<OrganisationDevisProps> = ({ type}) => {
    // const OrganisationDevisModal: React.FC<OrganisationDevisModalProps> = ({ show, onHide }) => {

    const apiRequest = useApiRequest()
    const [isLoading, setIsLoading] = useState(false)

    const formik = formHelper.useFormik({
        initialValues: initDevis,
        validationSchema: devisSchema,
        onSubmit: async (values) => {

            const checkResponse = await apiRequest({
                route: `last-devis`,
                method: 'GET'
            })

            if (checkResponse.status === 200) {
                setIsLoading(true)
                const reference = `D${orderReferenceGenerator()}${checkResponse.data.lastOrderId + 1}`
                const uid = `${generateRandomKey(24)}`
                try {
                    const data = {
                        reference: reference,
                        fullName: values.fullName,
                        email: values.email,
                        telephone: values.telephone,
                        uid: uid,
                        type: type,
                        date: values.date,
                        description: values.description,
                        created: FormatDate(new Date()),
                        updated: FormatDate(new Date())
                    }

                    const response = await apiRequest({
                        route: `devis`,
                        method: 'POST',
                        body: data,
                        headers: JSONHeader,
                    })
                    if (response.status === 201) {
                        // onHide()
                        setIsLoading(false)
                        notify('Votre devis â été envoyé')
                    } else {
                        FrCustomeErrorNorify()
                        setIsLoading(false)
                    }

                } catch {
                    FrCustomeErrorNorify()
                    setIsLoading(false)
                }
            }
        },
    })

    return (
        // <Modal show={show} onHide={onHide} centered dialogClassName='devis-modal'>
        //     <Modal.Header closeButton>
        //         <Modal.Title className='fs-4'>Details de Commande</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        <div className="container">
            <div className="row">
                <form onSubmit={formik.handleSubmit} >

                    <div className="col-12 mb-4">
                        <formHelper.FormInput
                            label="Nom et prenom"
                            spanValue="Nom et prenom"
                            name="fullName"
                            value={formik.values.fullName}
                            className={formik.touched.fullName && formik.errors.fullName ? "is-invalid" : ""}
                            touched={formik.touched}
                            errors={formik.errors}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            isRequired
                        />
                    </div>

                    <div className="col-12 mb-4">
                        <FormInput
                            label="Email"
                            spanValue="Email"
                            name="email"
                            value={formik.values.email}
                            className={formik.touched.email && formik.errors.email ? "is-invalid" : ""}
                            touched={formik.touched}
                            errors={formik.errors}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            isRequired
                        />
                    </div>

                    <div className="col-12 mb-4">
                        <FormInput
                            label="Telephone"
                            spanValue="Telephone"
                            name="telephone"
                            value={formik.values.telephone}
                            className={formik.touched.telephone && formik.errors.telephone ? "is-invalid" : ""}
                            touched={formik.touched}
                            errors={formik.errors}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            isRequired
                        />
                    </div>

                    <div className="col-12 mb-4">
                        <FormInput
                            label="Date"
                            spanValue="Date"
                            name="date"
                            type='datetime-local'
                            value={formik.values.date}
                            className={formik.touched.date && formik.errors.date ? "is-invalid" : ""}
                            touched={formik.touched}
                            errors={formik.errors}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            isRequired
                        />
                    </div>

                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                        <FormInput
                            label="Description"
                            spanValue="Description"
                            name="description"
                            textarea={true}
                            rows={6}
                            value={formik.values.description}
                            className={formik.touched.description && formik.errors.description ? "is-invalid" : ""}
                            touched={formik.touched}
                            errors={formik.errors}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                        />
                    </div>

                    <div className="col-12 mt-5 d-flex-start">
                        {isLoading ?
                            <loadingHelper.DottedLoading />
                            :
                            <SubmitButton
                                className="px-2rem py-14px submit-btn"
                                btnLabel="Envoyer votre devis"
                            />
                        }
                    </div>
                </form>
            </div>
        </div>

        //     </Modal.Body>
        // </Modal>
    )
}

export default OrganisationDevisModal