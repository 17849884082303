import BirthdayGiftSvg from '@assets/images/moments/moments-svg/birthday.svg'
import RomanticGiftSvg from '@assets/images/moments/moments-svg/gift-heart.svg'
import BirthGiftSvg from '@assets/images/moments/moments-svg/gift-birthday.svg'
import WeddingGiftSvg from '@assets/images/moments/moments-svg/wedding.svg'
import InvitationSvg from '@assets/images/moments/moments-svg/invitation.svg'
import ThankYouSvg from '@assets/images/moments/moments-svg/thanks.svg'
import CorporateGiftSvg from '@assets/images/moments/moments-svg/gift.svg'
import GiftCardsSvg from '@assets/images/moments/moments-svg/gift-card.svg'
import GourmetBreakSvg from '@assets/images/moments/moments-svg/gift-time.svg'

import BirthdayGiftImg from '@assets/images/moments/moments-images/birthday.jpg'
import RomanticGiftImg from '@assets/images/moments/moments-images/heat-gift.png'
import BirthGiftImg from '@assets/images/moments/moments-images/birth.jpg'
import WeddingGiftImg from '@assets/images/moments/moments-images/wedding.jpg'
import InvitationImg from '@assets/images/moments/moments-images/blue-gift.png'
import ThankYouImg from '@assets/images/moments/moments-images/blue-box.png'
import CorporateGiftImg from '@assets/images/moments/moments-images/bag.jpg'
import GiftCardsImg from '@assets/images/moments/moments-images/company-gifts.jpg'
import GourmetBreakImg from '@assets/images/moments/moments-images/break.jpg'

import BirthdayGiftImage from '@assets/images/moments/moment-details/BirthGiftImage.jpg'
import RomanticGiftImage from '@assets/images/moments/moment-details/RomanticGiftImage.jpg'
import BirthGiftImage from '@assets/images/moments/moment-details/BirthGiftImage.jpg'
import WeddingGiftImage from '@assets/images/moments/moment-details/WeddingGiftImage.jpg'
import InvitationImage from '@assets/images/moments/moment-details/InvitationImage.jpg'
import ThankYouImage from '@assets/images/moments/moment-details/ThankYouImage.jpg'
import CorporateGiftImage from '@assets/images/moments/moment-details/BirthGiftImage.jpg'
import GiftCardsImage from '@assets/images/moments/moment-details/GiftCardsImage.jpeg'
import GourmetBreakImage from '@assets/images/moments/moment-details/GourmetBreakImage.png'

export const GrandesOccasions = [
    {
        key: 1,
        title: `Cadeau d'anniversaire`,
        icon: BirthdayGiftSvg,
        image: BirthdayGiftImg,
        description: `Les chocolats s’invitent à la fête! Faites plaisir à tous les coups avec Leonidas.`,
        slug: 'cadeau-anniversaire',
        details: {
            firstDescription: `Un anniversaire ! Un gâteau, des bougies… Il ne manque plus que votre surprise Leonidas ! Surprenez la personne chère à votre cœur : offrez-lui ses chocolats préférés ou un assortiment varié.`,
            secondDescription: `Le témoignage parfait de votre affection

            « Que cache ce joli papier coloré ? Un jouet, un vêtement, un bibelot ? Oh !!! Des chocolats Leonidas ! Quelle délicieuse attention ! Merci, merci, merci ! » Vous savez qu’en offrant un assortiment des célèbres pralines belges, impossible de vous tromper. La joie se lit sur le visage de l’être cher, dont on célèbre l’anniversaire.`,
            title: ``,
            image: BirthdayGiftImage,
        }
    },
    {
        key: 2,
        title: `Cadeau romantique`,
        icon: RomanticGiftSvg,
        image: RomanticGiftImg,
        description: `Dites-le avec le coeur... et les meilleurs chocolats !`,
        slug: 'cadeau-romantique',
        details: {
            firstDescription: `Montrez votre amour de la plus délicieuse des façons. Véritables bijoux savoureux dans de splendides écrins, nos chocolats feront fondre votre moitié.`,
            secondDescription: `Dites-lui « Je t’aime » avec gourmandise ! En toute occasion, offrez-lui une élégante boîte de chocolats. Lors d’un dîner romantique, un intrigant ballotin de chocolats trône sur votre table. Imaginez l’instant magique où votre amour déballera son savoureux cadeau… Sourire complice garanti !`,
            title: `La plus délicieuse des déclarations d’amour`,
            image: RomanticGiftImage,
        }
    },
    {
        key: 3,
        title: `Cadeau de naissance`,
        icon: BirthGiftSvg,
        image: BirthGiftImg,
        description: `Une nouvelle vie ? Félicitez les proches avec un cadeau chocolaté.`,
        slug: 'cadeau-de-naissance',
        details: {
            firstDescription: `Faites plaisir aux heureux parents (et à leurs visiteurs gourmands !). Ce moment de partage restera à jamais gravé dans les mémoires.`,
            secondDescription: `Bébé est né. Tout le monde s’émerveille de ce petit être ! Ses parents ? Ils sont complètement épuisés ! Heureusement, vous êtes là, avec votre boîte de chocolats. La maman choisit sa friandise préférée. Elle la fait fondre en bouche, savoure cet instant et semble reprendre quelques forces. Sa moitié ne se fait pas non plus prier ! C’est beau une famille heureuse…`,
            title: `Les chocolats du bonheur`,
            image: BirthGiftImage,
        }
    },
    {
        key: 4,
        title: `Cadeau de mariage`,
        icon: WeddingGiftSvg,
        image: WeddingGiftImg,
        description: `Mariage chocolaté, mariage heureux ! Dites aux heureux époux tout le bonheur que vous leur souhaitez.`,
        slug: 'cadeau-de-mariage',
        details: {
            firstDescription: `L’une des premières choses que feront les mariés une fois la fête terminée ? Déguster vos chocolats en tête à tête ! Votre cadeau leur donnera envie de savourer l’instant.`,
            secondDescription: `Les mariés sont enfin seuls. La nuit de noces peut commencer. Ce qu’ils font ? Chut… c’est leur secret ! Goûter aux chocolats Leonidas que vous leur avez offerts ? Oh oui, très certainement ! Ils auront bien un petit creux après toutes ces émotions. Quoi de plus satisfaisant que de contribuer à leur bonheur, n’est-ce pas ?`,
            title: `La pause douceur après les festivités`,
            image: WeddingGiftImage,
        }
    }
]

export const Occasions = [
    {
        key: 5,
        title: `Invitation`,
        icon: InvitationSvg,
        image: InvitationImg,
        description: `Un dîner en famille? Une soirée entre amis? Remerciez délicieusement ceux qui vous accueillent.`,
        slug: 'invitation',
        details: {
            firstDescription: `Montrez à quel point l’invitation vous a fait plaisir: offrez en remerciement les créations de nos Maîtres Chocolatiers. Il n'y a rien de tel pour décrocher un sourire.`,
            secondDescription: `Vous êtes invités chez des amis? Venez les bras chargés de cadeaux. Offrez des chocolats! Avec Leonidas, vous avez l’embarras du choix. Surprenez vos hôtes avec nos élégants coffrets ou nos ballotins raffinés. Faites confiance à nos Maîtres Chocolatiers qui rivalisent de créativité. Grâce à leur talent, vous ferez sensation… et recevrez d’autres invitations!`,
            title: `La tradition d’offrir des chocolats`,
            image: InvitationImage,
        }
    },
    {
        key: 6,
        title: `Remerciements`,
        icon: ThankYouSvg,
        image: ThankYouImg,
        description: `Les meilleurs chocolats pour dire merci, tout simplement.`,
        slug: 'remerciements',
        details: {
            firstDescription: `Laissez nos chocolats se transformer en messagers du bonheur. Laissez-les dire merci de tout cœur. Une très jolie façon de montrer votre reconnaissance, en toute simplicité.`,
            secondDescription: `Vous avez été invité(e) ? Une personne vous a aidé(e) ? Vous souhaitez la remercier chaleureusement. Faites-lui plaisir : offrez-lui une boîte de chocolats Leonidas. Toute votre gratitude se trouve dans cet écrin de gourmandises. Chaque chocolat est un merci à savourer. On vous a procuré du bonheur ? Offrez à votre tour une bonne dose de bien-être !`,
            title: `Dites merci avec un feu d’artifice de saveurs`,
            image: ThankYouImage,
        }
    },
]

export const Cadeaux = [
    {
        key: 7,
        title: `Offre entreprises`,
        icon: CorporateGiftSvg,
        image: CorporateGiftImg,
        description: `Vous souhaitez remercier vos clients ou récompenser votre personnel?`,
        slug: 'offre-entreprises',
        details: {
            firstDescription: `Vous souhaitez remercier vos clients ou récompenser votre personnel ?

Découvrez notre offre accessible aux entreprises dans le cadre de leurs cadeaux d’affaires ou de cadeaux destinés à leur personnel.`,
            secondDescription: ``,
            title: ``,
            image: ``,
        }
    },
    {
        key: 8,
        title: `Chèques`,
        icon: GiftCardsSvg,
        image: GiftCardsImg,
        description: `Offrez un chèque Leonidas et laissez vos proches choisir leurs pralines et se faire plaisir.`,
        slug: 'cheques-cadeaux',
        details: {
            firstDescription: `Difficile de décider? Laissez vos proches choisir parmi les nombreuses surprises de Leonidas. Ils trouveront forcément leur bonheur.`,
            secondDescription: `Vous souhaitez faire un cadeau à un(e) ami(e) lointain(e)? Vous aimeriez lui offrir une boîte de chocolats? Votre chocolatier favori a pensé à vous! Découvrez le chèque-cadeau Leonidas! Petit et plat, il tient dans une enveloppe et fait la joie de celui ou celle qui le reçoit. Imaginez votre ami(e) se précipiter en boutique pour choisir ses chocolats préférés!`,
            title: `La solution pour les envois gourmands par la poste`,
            image: GiftCardsImage,
        }
    },
]

export const AutresMoments = [
    {
        key: 9,
        title: `Pause Gourmande`,
        icon: GourmetBreakSvg,
        image: GourmetBreakImg,
        description: `À tout moment de la journée, savourez l’instant avec nos chocolats 100% bonheur.`,
        slug: 'pause-gourmande',
        details: {
            firstDescription: `Mettez tout entre parenthèses, fermez les yeux et savourez… Vous méritez bien ce moment de douceur. Nos chocolats et autres gourmandises vous permettront de profiter pleinement de chaque seconde.`,
            secondDescription: `Accordez-vous une pause… gourmande et chocolatée! À toute heure de la journée, prenez quelques minutes. Avec nos pyramides, offrez-vous un véritable chocolat chaud. Vous préférez le café? Accompagnez-le d'une tablette ou d'un bâton Leonidas. Au petit-déjeuner ou au goûter, savourez notre pâte à tartiner. Profitez pleinement de ces instants de pur bonheur.`,
            title: `Votre coup de boost chocolaté`,
            image: GourmetBreakImage,
        }
    }
]
