import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApiRequest } from '@helpers/ApiRequest';
import { TruncateText, productImgUrl, variantImgUrl } from '@helpers/Helpers';
import { AuthUserProps, PricingProps, ProductCardProps } from '@helpers/Props';
import { FrCustomeErrorNorify } from '@helpers/Toastify';
import { loadingHelper } from '@helpers/Index';
import Icons from '@helpers/IconsHelper';
import './ProductCardStyle.css'
import { useShoppingCart } from '@contexts/ShoppingCartContext';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';

const ProductCard: FC<ProductCardProps> = ({ productIdsObj, item, isGrid, bsCol = 'col-md-6 col-lg-4 col-xl-3 col-xxl-3 col-sm-6' }) => {

  // Global
  const apiRequest = useApiRequest();
  const { addToCart } = useShoppingCart();
  const [discount, setDiscount] = useState(null);
  const [haveDiscount, setHaveDiscount] = useState(false);
  const [isGetDiscount, setIsGetDiscount] = useState(false);
  const [productPrice, setProductPrice] = useState<number>(item.sellingPrice);
  const [variantPrice, setVariantPrice] = useState<number>(item.sellingPrice);
  const [minPrice, setMinPrice] = useState<number>(item.sellingPrice);
  const [maxPrice, setMaxPrice] = useState<number>(item.sellingPrice);
  const [isVariant, setIsVariant] = useState(false);
  const [isDisplayAll, setIsDisplayAll] = useState(false);
  const [isWishlistHover, setIsWishlistHover] = useState(false);
  const { windowWidth } = useWindowWidthContext()

  // Selected Variant
  const [selectedVariant, setSelectedVariant] = useState<any>('');
  const [selectedVariantId, setSelectedVariantId] = useState<number>();
  const [selectedVariantImage, setSelectedVariantImage] = useState<string>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  // User
  const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
  const storedUser = localStorage.getItem('leon_user');

  useEffect(() => {
    if (storedUser) {
      setAuthUser(JSON.parse(storedUser));
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsGetDiscount(false)
      if (productIdsObj.isProductIds) {
        if (productIdsObj.productIds.length > 0) {
          if (productIdsObj.productIds.includes(item.id)) {

            const response = await apiRequest({
              route: `promotion-discount/product_id/${item.id}`,
              method: 'GET',
            });

            if (response.status === 200) {
              setDiscount(response.data.discount)
              setIsGetDiscount(true)
              if (response.data.discount !== null) {
                setHaveDiscount(true)
                setProductPrice(item.sellingPrice - (item.sellingPrice * (response.data.discount / 100)))
              }
              else {
                setProductPrice(item.sellingPrice)
              }
            } else {
              setIsGetDiscount(false)
              setHaveDiscount(false)
              FrCustomeErrorNorify()
            }
          }// End check includes if
          else {
            setIsGetDiscount(true)
            setHaveDiscount(false)
            setProductPrice(item.sellingPrice)
          }
        } else {
          setIsGetDiscount(true)
        }
      }

    } catch {

    }
  }

  useEffect(() => {
    fetchData();
    if (item.variant && item.variant?.length > 0) {
      const selectedOne = item.variant[0].size
      setMinPrice(item.variant[0].sellingPrice)
      // console.log(item.variant[item.variant?.length - 1].sellingPrice);
      setMaxPrice(item.variant[item.variant?.length - 1].sellingPrice)
      setSelectedVariant(selectedOne)
      setSelectedVariantId(item.variant[0].id)
      setSelectedVariantImage(item.variant[0].image)
    }
  }, [item.id, productIdsObj.productIds, haveDiscount])


  useEffect(() => {
    setIsVariant(false)
    if (haveDiscount && discount !== null && item.variant && item.variant?.length > 0) {
      let sellingPrice = item.variant[selectedIndex].sellingPrice
      if (sellingPrice !== item.sellingPrice) {
        setVariantPrice(sellingPrice - (sellingPrice * (discount / 100)))
        setIsVariant(true)
      }
    }
  }, [item.id, selectedIndex, selectedVariantId])


  const Pricing = ({ price, selling }: PricingProps) => {
    return (
      <>
        {
          isGrid && windowWidth < 590 ?
            <>
              <p className='p-discount-price'><del className='me-2 discount-price ff-lato-reg fw-600'>{selling?.toFixed(2)} MAD</del></p>
              <p className='m-0'><span className='clr-blue'>{price?.toFixed(2)} MAD</span></p>
            </>
            :
            <>
              <p className='m-0'><span className='clr-blue'>{price?.toFixed(2)} MAD</span></p>
              <p className='p-discount-price'><del className='me-2 discount-price ff-lato-reg fw-600'>{selling?.toFixed(2)} MAD</del></p>
            </>
        }

      </>
    )
  }

  const hideIcon = () => {
    setIsWishlistHover(false);
  };

  return (
    <div className={`${bsCol} p-0 ${isGrid ? 'col-6 isgrid' : 'col-12 isrow'} product-item min-h-23rem mb-3rem`} onMouseLeave={hideIcon} key={item.slug}>
      <div className="container">
        <div className="row d-flex-center">
          <div className="col-12 mb-1rem">
            <div className={`product-image text-center dflex justify-content-center ${isGrid && windowWidth < 590 ? 'h-15rem' : 'h-19rem'}`}>
              {haveDiscount
                ?
                <span className='position-fixed discount-value '>{`-${discount} %`}</span>
                :
                null
              }

              <Link to={`/shop/produit/${item.slug}`} state={item} onMouseLeave={hideIcon} onMouseEnter={hideIcon} >
                {/* <img
                  className="product-img mb-5"
                  alt={item.title}
                  src={`${selectedVariantImage ?
                    variantImgUrl : productImgUrl}/${selectedVariantImage ?
                      selectedVariantImage :
                      item.image}`} /> */}

                <img
                  className="product-img mb-5"
                  alt={item.title}
                  src={`${productImgUrl}/${item.image}`} />
              </Link>
            </div>
          </div>


          <div className="text-center">
            <div className="product-details text-start">
              <div className={`product-info text-center`}>
                <Link className={`nav-link min-h-${windowWidth > 578 ? '5' : ''}-rem`} to={`/shop/produit/${item.slug}`} state={item}>
                  <div className={`product-title ff-ubuntu-reg fs-20px clr-blue `}>
                    <TruncateText className='text-cap' text={item.title} maxLength={80} />
                    {/* <TruncateText className='text-cap' text={item.title} maxLength={80} /> {` ${item.variant.length > 0 ? `- ${selectedVariant}` : ''}`} */}
                  </div>
                </Link>

                <div className={` row mb-1 ${isGrid && windowWidth < 590 ? 'isgrid' : 'isrow'}`}>
                  <div className={`col-12 text-center ps-0 pe-0`}>
                    <div className={`product-price h-3rem d-flex-center ff-ubuntu-reg fs-18px clr-dark ${isGrid && windowWidth < 590 ? 'd-flex-center' : ''}`}>
                      {haveDiscount
                        ? isVariant ?
                          <Pricing price={variantPrice} selling={item.variant[selectedIndex].sellingPrice} />
                          :
                          <Pricing price={productPrice} selling={item.sellingPrice} />
                        :
                        <span>{item.variant.length > 0 ? `${minPrice} MAD - ${maxPrice} MAD` : `${productPrice} MAD`} </span>
                      }
                    </div>
                  </div>

                  <div className={`d-none col-${isGrid && windowWidth < 590 ? 12 : 7} ${isGrid && windowWidth < 590 ? 'px-0' : 'pe-0'} text-end shopping-cart-add-to d-flex justify-content-end`}>
                    {isGetDiscount ?
                      <div
                        className={`${false ? 'shopping-cart-size-parent' : 'addtocart-parent p-0'} d-flex-center  c-pointer ff-lato-reg`}
                        onClick={() => {
                          const variantId = selectedVariantId ? selectedVariantId : '';
                          if (item.variant && item.variant?.length > 0) {
                            addToCart(item.id, isVariant ? Number(variantPrice) : productPrice, 'product', variantId, 1, item.sellingPrice, true);
                          } else {
                            addToCart(item.id, isVariant ? Number(variantPrice) : productPrice, 'product', '', 1, item.sellingPrice);
                          }
                        }}
                      >
                        {false ?
                          <Icons.MdShoppingCart className='shopping-cart-icon' />
                          :
                          'Ajouter au panier'
                        }
                      </div>
                      :
                      <div className="loading-discount">
                        <loadingHelper.DottedLoading />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;