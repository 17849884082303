import { FC } from 'react'
import './QualitySection.css'

import img1 from '../../../assets/images/Quality/pure-cocoa-butter-en.png'
import img2 from '../../../assets/images/Quality/LEONIDAS_ICON_QUALITY_PALMOIL_EN_RGB_BLUE.png'
import img3 from '../../../assets/images/Quality/LEONIDAS_ICON_DURABLE_BELGIAN_UK_RGB_BLUE.png'
import img4 from '../../../assets/images/Quality/belgium-1913-en.png'
import SubmitButton from '../SubmitButton/SubmitButton'
import Icons from '@helpers/IconsHelper'
import NewsEmail from '@components/NewsEmail/NewsEmail'

const QualitySection: FC = () => {
    return (
        <>
            <div className="quality-section-top ox-hidden quality-section">
                <div className="quality-section-top-image text-center">
                    <p className='fs-2rem pt-4rem'>
                        <span className='ff-ubuntu-m fw-600 clr-light'>La </span>
                        <span className='ff-satisfy clr-yellow '> qualité</span>
                        <span className='ff-ubuntu-m fw-600 clr-light'> est notre plus grand atout</span>
                        </p>
                </div>

                <div className="quality-section-top-cards d-flexcenter">
                    <div className="row d-flex-center">

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 d-flex-center flex-wrap quality-section-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                <img src={img1} alt="" />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-ubuntu-reg fw-600 fs-30px'>100% pur beurre de cacao</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center px-2">
                                <div className="sociall d-inline-flex ff-ubuntu-reg clr-blue">
                                Leonidas veut vous faire vivre des moments de pur délice. Voilà pourquoi nos Maîtres Chocolatiers n’utilisent que du chocolat 100% pur beurre de cacao.
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 d-flex-center flex-wrap quality-section-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                <img src={img2} alt="" />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-ubuntu-reg fw-600 fs-30px'>Sans huile de palme</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center px-2">
                                <div className="sociall d-inline-flex ff-ubuntu-reg clr-blue">
                                Nos Maîtres Chocolatiers ont l’obsession de la qualité. Ils ne veulent que du pur beurre de cacao. Vous ne trouverez donc pas la moindre goutte d’huile de palme dans notre chocolat.
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 d-flex-center flex-wrap quality-section-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                <img src={img3} alt="" />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-ubuntu-reg fw-600 fs-30px'>Belge depuis 1913</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center px-2">
                                <div className="sociall d-inline-flex ff-ubuntu-reg clr-blue">
                                Les chocolats Leonidas sont élaborés en Belgique. Nos Maîtres Chocolatiers respectent la tradition et transforment avec amour les ingrédients les plus fins.
                                </div>
                            </div>

                        </div>


                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 d-flex-center flex-wrap quality-section-top-cards-item">

                            <div className="w-100 text-center cards-item-parent">
                                <img src={img4} alt="" />
                            </div>

                            <div className="w-100 text-center cards-item-text">
                                <p className='ff-ubuntu-reg fw-600 fs-30px'>Cacao durable</p>
                            </div>

                            <div className="w-100 text-center cards-item-value d-flex-center px-2">
                                <div className="sociall d-inline-flex ff-ubuntu-reg clr-blue">
                                Leonidas opte pour le cacao durable pour la production de ses chocolats. Quoi de plus normal, quand on a pour mission de créer des moments de bonheur pour tous, que de se préoccuper de l’Homme et de la nature ?
                                </div>
                            </div>

                        </div>

                    </div>

                    {/* <div className="row keep-up">
                    <div className="container d-flex-around">
                        <div className="row d-flex-between-center">
                            <div className="col-4 d-flex-start text-start">
                                <Icons.RiMailOpenFill className='clr-blue fs-40px me-2 mb-px-17' />  <p className='clr-blue fs-35px fw-600 ff-ubuntu-reg'>Restez informé(e)</p>
                            </div>

                            <div className="col-5">
                                <p className='clr-blue'>Inscrivez-vous à la newsletter et recevez dans votre mailbox les surprises et gourmandises de Leonidas.</p>
                            </div>

                            <div className="col-3 text-end">
                                <SubmitButton btnLabel={`Je m'inscris`}
                                    className='py-13px submit-btn' />
                            </div>
                        </div>
                    </div>
                </div> */}

                </div >
                <NewsEmail />
            </div >

        </>
    )
}

export default QualitySection
