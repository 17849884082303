import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserDashboard } from '../UserDashboard/UserDashboard';
import { useApiRequest } from '@helpers/ApiRequest';
import { AuthUserProps, UserAdresseProps } from '@helpers/Props';
import { FrCustomeErrorNorify } from '@helpers/Toastify';
import { loadingHelper } from '@helpers/Index';
import { ReactHelmet } from '@helpers/Helpers';
import Icons from '@helpers/IconsHelper';
import location from '../../../../../assets/images/account/location.png'
import './EditAddressStyle.css'

const Content: React.FC = React.memo(() => {

    const apiRequest = useApiRequest();
    const [facturationAdresse, setFacturationAdresse] = useState<UserAdresseProps[]>([]);
    const [livraisonAdresse, setLivraisonAdresse] = useState<UserAdresseProps[]>([]);
    const [user, setUser] = useState<AuthUserProps | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const storedUser = localStorage.getItem('leon_user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);


    const fetchUserAdresse = async () => {
        try {
            setIsLoading(true)
            if (user?.id) {
                const response = await apiRequest({
                    route: `user_adresses/user_id/${user?.id}`,
                    method: 'GET',
                });

                if (response.status === 200) {
                    setLivraisonAdresse(response.data.livraisonAdresse);
                    setFacturationAdresse(response.data.facturationAdresse);
                    setIsLoading(false)
                } else {
                    FrCustomeErrorNorify()
                    setIsLoading(false)
                }
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchUserAdresse();
    }, [user?.id])

    return (
        <>
            <ReactHelmet title='Adresses' />
            <div className="col-11 col-lg-7 col-md-7 col-xl-8 col-xxl-9 ms-4">
                {
                    isLoading ?
                        <loadingHelper.AnimatedLoading label='Des Adresses' className='col-12' />
                        :
                        <div className="account-order ms-4 bm-me-3">
                            <div className="order-message d-flex-center mb-5">
                                <span className='account-notif'><Icons.BsChatRightDots className='order-message-icon' /> Les adresses suivantes seront utilisées par défaut sur la page de commande.</span>
                            </div>
                            <div className={`account-adresses order-content ${livraisonAdresse.length === 0 || facturationAdresse.length === 0 ? 'd-flex-center' : ''}`}>
                                <div className="conatiner text-center mt-2">
                                    {livraisonAdresse.length > 0 || facturationAdresse.length > 0 || true
                                        ?
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-12 mb-4 text-left">
                                                <div className='nav-link'>
                                                    <h5 className='text-center'>Adresse de facturation
                                                        {
                                                            facturationAdresse[0]?.adresseType === 'facturation' ?
                                                                <Link to={`facturation/${facturationAdresse[0]?.id}`}>
                                                                    <span className='account-adresse-edit-content'>
                                                                        <Icons.CiEdit className='account-adresse-size-edit' />
                                                                    </span>
                                                                </Link>
                                                                :
                                                                <Link to='facturation'><Icons.BsPlusCircleDotted className='account-adresse-icon' /></Link>
                                                        }
                                                    </h5>
                                                </div>

                                                {facturationAdresse[0]?.adresseType === 'facturation' ?
                                                    <div className="containe">
                                                        <div className="row text-start mt-5">

                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Nom Prenom :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.nom} ${facturationAdresse[0]?.prenom}`}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Telephone :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.telephone}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Adresse Email :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.email}`}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>N° et Nom de rue :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.rueInfo}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Ville :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.ville}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Région :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.region ? facturationAdresse[0]?.region : 'Non ajouté'}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Code postal :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.codepostal ? facturationAdresse[0]?.codepostal : 'Non ajouté'}`}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    :

                                                    <div className='d-flex-center'>
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-12 d-flex-center">
                                                                    <img className='mt-5 nolocation-img ' src={location} alt="location" />
                                                                </div>
                                                                <div className="col-12">
                                                                    <h5 className='adress-details-value mt-5 text-center clr-blue'>
                                                                        Adresse de facturation Non ajouté
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                }
                                            </div>
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-12 text-left">
                                                <h5 className='text-center'>Adresse de livraison
                                                    {
                                                        livraisonAdresse[0]?.adresseType === 'livraison' ?
                                                            <Link to={`livraison/${livraisonAdresse[0].id}`}>
                                                                <span className='edit-livraison-icon account-adresse-edit-content'>
                                                                    <Icons.CiEdit className='account-adresse-size-edit' />
                                                                </span>
                                                            </Link>
                                                            :
                                                            <Link to='livraison'><Icons.BsPlusCircleDotted className='livraison-icon account-adresse-icon' /></Link>
                                                    }
                                                </h5>

                                                {
                                                    livraisonAdresse[0]?.adresseType === 'livraison'
                                                        ?
                                                        <div className="containe">
                                                            <div className="row text-start mt-5">

                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Nom Prenom :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.nom} ${livraisonAdresse[0]?.prenom}`}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Telephone :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.telephone}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Adresse Email :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.email}`}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>N° et Nom de rue :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.rueInfo}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Ville :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.ville}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Région :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.region ? livraisonAdresse[0]?.region : 'Non ajouté'}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <Icons.BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Code postal :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.codepostal ? livraisonAdresse[0]?.codepostal : 'Non ajouté'}`}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='d-flex-center'>
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex-center">
                                                                        <img className='mt-5 nolocation-img ' src={location} alt="location" />
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <h5 className='adress-details-value mt-5 text-center clr-blue'>
                                                                            Adresse de livraison Non ajouté
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                }

                                            </div>
                                        </div>
                                        :
                                        <>
                                            <loadingHelper.LoadingMore />
                                            <p className='adress-details-value position-absolute' style={{ margin: '0 0 0 -4rem' }}>
                                                Chargement des adresses...
                                            </p>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    );
});


const EditAddress: React.FC = () => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export { EditAddress };