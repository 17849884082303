import { ProductSwiperProps } from "@helpers/Props";
import { ProductCard, hooks } from "@helpers/Index";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useWindowWidthContext } from "@contexts/WindowWidthContext";
import './ProductSwiper.css'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const ProductSwiper: React.FC<ProductSwiperProps> = ({ items, itemType }) => {
    const { productIds, isProductIds } = hooks.useProductIds();
    const { windowWidth } = useWindowWidthContext()

    let screen = 5

    if (windowWidth < 1400 && windowWidth >= 1200) {
        screen = 5
    } else if (windowWidth < 1200 && windowWidth >= 992) {
        screen = 3
    }
    else if (windowWidth < 992 && windowWidth >= 768) {
        screen = 3
    } else if (windowWidth < 768 && windowWidth >= 578) {
        screen = 2
    } else if (windowWidth < 578) {
        screen = 1
    }



    return (
        <>
            <Swiper
                className="mySwiper"
                slidesPerView={screen}
                // loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{
                    clickable: true,
                }}
                // autoplay={false}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}

            >
                {items.map((item: any) => (
                    <SwiperSlide key={item.id} className="scale-11">
                        <ProductCard key={item.id} item={item} productIdsObj={{ productIds, isProductIds }} bsCol="col-12" />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default ProductSwiper;