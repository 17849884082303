import { Link } from 'react-router-dom'
import { MomentCardProps } from '@helpers/Props'
import './MomentCard.css'

const MomentCard: React.FC<MomentCardProps> = ({ item }) => {
    return (
        <div className="col-lg-6 col-12 mb-4rem">
            <Link to={item.slug} className='moment-card w-100 nav-link'>
                <div className="moment-card-image o-hidden w-100 radius-10px">
                    <img src={item.image} alt={item.title} className='w-100 h-100 radius-10px' />
                </div>
                <div className="moment-card-title mt-2rem">
                    <p className='c-pointer'>
                        <img src={item.icon} alt={item.title} className='w-6rem' />
                        <span className='fw-600 clr-blue ff-ubuntu-m f-600 fs-28px'>{item.title}</span>
                    </p>
                </div>
                <div className="moment-card-description ps14px">
                    <p className='clr-dark fs-19px pe-10px'>{item.description}</p>
                </div>
            </Link>
        </div>
    )
}

export default MomentCard