import { AiFillHome } from 'react-icons/ai'
import { BiPlus } from 'react-icons/bi'
import {
    BsCheckCircleFill,
    BsChatRightDots,
    BsCircle,
    BsDot,
    BsFacebook,
    BsFillTrashFill,
    BsGridFill,
    BsPlusCircle,
    BsPlusCircleDotted,
    BsSearch,
    BsStars,
    BsEnvelopePaper,
} from 'react-icons/bs'
import { CiCircleMore, CiEdit, CiGrid2H } from 'react-icons/ci'
import { FaFacebookF, FaFilter, FaLinkedinIn, FaYoutube, FaTelegramPlane } from 'react-icons/fa'
import { FaLocationDot, FaUser } from 'react-icons/fa6'
import { FiSearch, FiShoppingCart } from 'react-icons/fi'
import { GrFavorite, GrFormClose } from 'react-icons/gr'
import { HiMinusSm, HiOutlineMinusSm, HiOutlineUserCircle } from 'react-icons/hi'
import { ImPhone } from 'react-icons/im'
import {
    MdContactMail,
    MdDelete,
    MdEmail,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineFavoriteBorder,
    MdOutlineHelpOutline,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp
} from 'react-icons/md'
import { RiInstagramFill, RiMailOpenFill } from 'react-icons/ri'

const Icons = {
    AiFillHome,
    BiPlus,
    BsCheckCircleFill,
    BsChatRightDots,
    BsCircle,
    BsDot,
    BsFacebook,
    BsFillTrashFill,
    BsGridFill,
    BsPlusCircle,
    BsPlusCircleDotted,
    BsSearch,
    BsStars,
    BsEnvelopePaper,
    CiCircleMore,
    CiEdit,
    CiGrid2H,
    FaFacebookF,
    FaFilter,
    FaLinkedinIn,
    FaLocationDot,
    FaYoutube,
    FaTelegramPlane,
    FaUser,
    FiSearch,
    FiShoppingCart,
    GrFavorite,
    GrFormClose,
    HiMinusSm,
    HiOutlineMinusSm,
    HiOutlineUserCircle,
    ImPhone,
    MdContactMail,
    MdDelete,
    MdEmail,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineFavoriteBorder,
    MdOutlineHelpOutline,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp,
    RiInstagramFill,
    RiMailOpenFill
};

export default Icons;