import { FC } from 'react'
import { OffCanvasProps } from '@helpers/Props';
import Icons from '@helpers/IconsHelper';
import './OffCanvas.css'


const OffCanvas: FC<OffCanvasProps> = ({ position, content, label, id, closeBtnRef }) => {

    return (
        <div className={`offcanvas offcanvas-${position}`} tabIndex={-1} id={id} aria-labelledby="OffCanvasLabel">
            <div className="offcanvas-header">
                <h5 id="OffCanvasLabel" className='clr-dark text-upper'>{label}</h5>
                <button
                    ref={closeBtnRef}
                    type="button"
                    className="btn offcanvas-close-btn text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close">
                    <Icons.GrFormClose className='offcanvas-close-icon clr-dark' />
                </button>
            </div>
            <div className="offcanvas-body">
                {content}
            </div>
        </div>
    )
}

export default OffCanvas