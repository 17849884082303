import React, { useCallback, useEffect, useState } from 'react';
import { UserDashboard } from '../../UserDashboard/UserDashboard';
import { useApiRequest } from '@helpers/ApiRequest';
import { AddingNotify, UpdateNotify } from '@helpers/Toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthUserProps } from '@helpers/Props';
import { userAdresseShema } from '@helpers/YupHelper';
import { initOrderAdresse } from '@helpers/FormikHelper';
import { formHelper, loadingHelper } from '@helpers/Index';
import { ReactHelmet } from '@helpers/Helpers';

const Content: React.FC = () => {

    const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('leon_user');
        if (storedUser) {
            setAuthUser(JSON.parse(storedUser));
        }
    }, []);

    const paysRegion = 'Maroc';
    const adresseTypeF = "facturation"
    const adresseTypeL = "livraison"
    const apiRequest = useApiRequest()
    const user = `api/users/${authUser?.id}`;
    const { adresseId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [matchAdresse, setMatchAdresse] = useState(false);
    const [haveAdresse, setHaveAdresse] = useState(false);

    const formik = formHelper.useFormik({
        initialValues: initOrderAdresse,
        validationSchema: userAdresseShema,
        onSubmit: async (values) => {
            const data = { ...values, user, adresseType: adresseTypeL }
            const facturationData = { ...values, user, adresseType: adresseTypeF }
            setIsLoading(true)

            if (adresseId) {
                const response = await apiRequest({
                    route: `user_adresses/${adresseId}`,
                    method: 'PUT',
                    body: data
                });

                if (response.status === 200) {
                    UpdateNotify('Adresse');
                    navigate('/shop/my-account/edit-address');
                }
            }

            else {
                if (matchAdresse) {
                    const responseF = await apiRequest({
                        route: `user_adresses`,
                        method: 'POST',
                        body: data
                    });
                    const responseL = await apiRequest({
                        route: `user_adresses`,
                        method: 'POST',
                        body: facturationData
                    });

                    if (responseF.status === 201 && responseL.status === 201) {
                        AddingNotify('Adresse')
                        navigate('/shop/my-account/edit-address');
                    }
                }
                else {
                    const response = await apiRequest({
                        route: `user_adresses`,
                        method: 'POST',
                        body: data
                    });

                    if (response.status === 201) {
                        AddingNotify('Adresse')
                        navigate('/shop/my-account/edit-address');
                    }
                }
            }

        },
    });

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setValues
    } = formik;

    const fetchData = useCallback(async () => {
        try {
            if (authUser?.id && adresseId) {
                const response = await apiRequest({
                    route: `user_livraison_adresse/user_id=${authUser?.id}&adresse_id=${adresseId}`,
                    method: 'GET',
                });

                if (response.status === 200) {
                    await setValues({
                        nom: response.data.livraisonAdresse[0].nom,
                        prenom: response.data.livraisonAdresse[0].prenom,
                        pays: paysRegion,
                        rueInfo: response.data.livraisonAdresse[0].rueInfo,
                        ville: response.data.livraisonAdresse[0].ville,
                        region: response.data.livraisonAdresse[0].region,
                        codepostal: response.data.livraisonAdresse[0].codepostal,
                        telephone: response.data.livraisonAdresse[0].telephone,
                        email: response.data.livraisonAdresse[0].email,
                    });
                }
            }
        } catch (error) {

        }
    }, [adresseId, authUser?.id]);

    const checkAdresse = useCallback(async () => {
        try {
            if (authUser?.id && !adresseId) {
                const response = await apiRequest({
                    route: `check-adresse/userId=${authUser.id}&adresseType=${adresseTypeF}`,
                    method: 'GET',
                });

                if (response.data.count === 1) {
                    setHaveAdresse(true);
                }

            }
        } catch (error) {

        }
    }, [authUser?.id]);

    useEffect(() => {
        fetchData();
        checkAdresse();
    }, [authUser?.id, adresseId])

    const handleMatchAdresseChange = () => {
        setMatchAdresse(!matchAdresse);
    };

    return (
        <>
            <ReactHelmet title='Adresse de livraison' />
            <div className="col-12 col-lg-7 col-md-7 col-xl-8 col-xxl-9 bm-ms-4">
                <div className="container contact-container d-flex-center margin-left">

                    <form action="" onSubmit={handleSubmit}>
                        <div className="edit-form">
                            <div className="row d-flex-start">

                                <h4 className='text-start mb-4 mt-2' >Adresse de livraison</h4>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Nom *"
                                        spanValue="Nom "
                                        name="nom"
                                        type="text"
                                        value={values.nom}
                                        className={`${touched.nom && errors.nom ? "is-invalid" : ""}`}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Prenom *"
                                        spanValue="Prenom "
                                        name="prenom"
                                        type="text"
                                        value={values.prenom}
                                        className={`${touched.prenom && errors.prenom ? "is-invalid" : ""}`}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-3 d-none">
                                    <label className={`form-label`}>
                                        Pays/Region *
                                    </label>
                                    <p>{values.pays}</p>
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Numéro et nom de rue *"
                                        spanValue="Numéro et nom de rue"
                                        name="rueInfo"
                                        type="text"
                                        value={values.rueInfo}
                                        className={`${touched.rueInfo && errors.rueInfo ? "is-invalid" : ""}`}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Ville *"
                                        spanValue="Ville"
                                        name="ville"
                                        type="text"
                                        value={values.ville}
                                        className={`${touched.ville && errors.ville ? "is-invalid" : ""}`}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Région"
                                        spanValue="Région"
                                        name="region"
                                        type="text"
                                        value={values.region}
                                        className={`${touched.region && errors.region ? "is-invalid" : ""}`}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Code postal *"
                                        spanValue="Code postal"
                                        name="codepostal"
                                        type="text"
                                        value={values.codepostal}
                                        className={`${touched.codepostal && errors.codepostal ? "is-invalid" : ""}`}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Telephone *"
                                        spanValue="Telephone"
                                        name="telephone"
                                        type="text"
                                        value={values.telephone}
                                        className={`${touched.telephone && errors.telephone ? "is-invalid" : ""}`}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <formHelper.FormInput
                                        label="Email *"
                                        spanValue="Email"
                                        name="email"
                                        type="text"
                                        value={values.email}
                                        className={`${touched.email && errors.email ? "is-invalid" : ""}`}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>
                                {!adresseId && !haveAdresse
                                    ?
                                    <div className="col-12 col-md-12 col-lg-12 mb-4">
                                        <div className="form-check mt-3 mb-4 ms-1">
                                            <input
                                                className="custom-form-check form-check-input shopping-form-check-input"
                                                type="checkbox"
                                                id="matchAdresse"
                                                onChange={handleMatchAdresseChange}
                                            />
                                            <label className="form-check-label fs-18 ff-lato-reg fw-bold ms-1" htmlFor="matchAdresse">
                                                Ajouter comme adresse de facturation
                                            </label>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }

                                <div className="col-12 col-md-12 col-lg-12 mt-2 d-flex-start">
                                    {isLoading ?
                                        <loadingHelper.DottedLoading />
                                        :
                                        <formHelper.SubmitButton
                                            className="mt-2 px-3 py-13px submit-btn-1"
                                            btnLabel="Enregistrer les modifications"
                                        />
                                    }
                                </div>

                            </div>

                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};

const AdresseLivraison: React.FC = (props) => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export default AdresseLivraison;