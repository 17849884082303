import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './ReactLink.css';

interface LinkProps {
  className?: string;
  label?: string;
  to: string;
  content?: ReactNode;
}

const ReactLink: React.FC<LinkProps> = ({
  className = '',
  label = '',
  to = '',
  content = '',
}) => {
  return (
    <Link to={to} className={`nav-link btn link-btn ${className}`}>
      {content}{label}
    </Link>
  );
};

export default ReactLink;