import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { homeBannerBg } from '@helpers/Index'
import './HomeBanner.css'

const HomeBanner: React.FC = () => {
    const { windowWidth } = useWindowWidthContext()


    return (
        <div className={`home-banner bg-blue-sky my-6rem min-h-23rem max-h-${windowWidth > 992 ? '23rem' : '40rem'} mx-0`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 mt-3rem">
                        <h2 className='ff-ubuntu-m clr-light pb-10px'>Offrez du bonheur avec des chocolats Leonidas livrés chez vous !</h2>

                        <p className='ff-ubuntu-reg clr-light fs-18px'>
                            Tout le monde apprécie la saveur et la qualité des chocolats Leonidas ! Faites-vous livrer nos célèbres pralines en commandant sur la boutique en ligne officielle Leonidas. Nos délicieux chocolats sont magnifiquement présentés dans de ravissants emballages qui mettent en valeur les trésors gourmands qu’ils renferment. La commande en ligne de produits Leonidas est très facile et la livraison est gratuite dès 45 euros d’achats.
                        </p>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="home-banner-image h-100 d-flex-end position-relative">
                            <img src={homeBannerBg} alt="home banner" className='home-banner-img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner
