import { FC, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApiRequest } from '@helpers/ApiRequest';
import { ProductProps } from '@helpers/Props';
import { logo } from '@helpers/Index';
import useClickOutside from '@hooks/useClickOutside';
import OffCanvas from '../../../OffCanvas/OffCanvas';
import './ShopNavbar.css'
import { NavLink } from '@helpers/Helpers';
import { useCategoriesContext } from '../../../../contexts/CategoriesContext';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

let timeout: NodeJS.Timeout;
let marquetTimeout: NodeJS.Timeout;

const ShopNavbar: FC = () => {

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
      clearTimeout(marquetTimeout);
    };
  }, []);

  // Global
  const navigate = useNavigate();
  const apiRequest = useApiRequest()

  // Contexts
  const { windowWidth } = useWindowWidthContext()
  const { categories, isLoading } = useCategoriesContext()

  // States
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMarqueDropdownOpen, setIsMarqueDropdownOpen] = useState(false);
  const [isGiftDropdownOpen, setIsGiftDropdownOpen] = useState(false);
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [textValue, setTextValue] = useState('');

  // ShoppingCartBox close
  const elementRef = useRef<HTMLDivElement>(null);
  const blockRef = useRef<HTMLDivElement>(null);

  // OffCanvas close
  const closeBtnRef = useRef<HTMLButtonElement | null>(null);
  const closeCBtnRef = useRef<HTMLButtonElement | null>(null);

  const closeOffCanvas = () => {
    if (closeBtnRef.current) {
      closeBtnRef.current.click();
    }
  };

  const closeCOffCanvas = () => {
    if (closeCBtnRef.current) {
      closeCBtnRef.current.click();
    }
  };

  const handleShoppingCartBoxClose = () => {
    setShoppingCartBoxVisible(false)
  };

  useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef);


  const openModal = (product: any) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDropdownMouseEnter = () => {
    if (windowWidth > 992) {
      clearTimeout(timeout);
      setIsDropdownOpen(true);
    }
  };


  const handleDropdownMouseLeave = () => {
    if (windowWidth > 992) {
      timeout = setTimeout(() => {
        setIsDropdownOpen(false);
      }, 200);
    }
  };

  const handleMarqueDropdownMouseEnter = () => {
    clearTimeout(marquetTimeout);
    setIsMarqueDropdownOpen(true);
  };

  const handleMarqueDropdownMouseLeave = () => {
    marquetTimeout = setTimeout(() => {
      setIsMarqueDropdownOpen(false);
    }, 200);
  };



  useEffect(() => {
    if (!searchBoxVisible) {
      setProducts([])
      setTextValue('');
    }
  }, [searchBoxVisible])

  const marquesContent =
    <div className="row ox-hidden" >

    </div>


  const categoriesContent =
    <div className="row">

    </div>



  return (
    <>
      <header className="shop-navbar w-100 d-flexjustify-content-start align-items-center ">
        <div className="w-100 mx-6rem top-container d-flex-center h-100 d-none">
          <div className="row">
            <div className="col-12 mt-2">
              <nav className="navbar navbar-expand-xxl navbar-right">
                <div className="container-fluid d-flex justify-content-start align-items-center">

                  <div className="responsive-icon">
                    <button className={`navbar-toggler ${windowWidth < 992 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>

                  <div className={`navbar-brand m-logo me-4rem mb-3rem `}>
                    <Link to="/">
                      <img src={logo} alt="leonidas" className='leonidas shop-brand-logo' />
                    </Link>
                  </div>

                  <div className="w-100 collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                      {!isLoading && categories.map((item) => (
                        <NavLink key={item.id} to={`/shop/${item.slug}`} label={item.title} listStyle='px-12px' linkStyle='shop-navlink fs-20px clr-light' />
                      ))}

                      <NavLink label='Cartes cadeau' listStyle='px-12px' linkStyle="shop-navlink fs-20px clr-light" to="/shop/cartes-cadeau" />
                      <NavLink label='Tous les produits' listStyle='px-12px' linkStyle="shop-navlink fs-20px clr-light" to="/shop/tous-les-produits" />

                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <Container className='z-index-999 bg-blue w-100'>
          <Navbar bg="" expand="lg" className='bg-blue ff-ubuntu-m w-100'>
            <Navbar.Brand href="/">
              <img
                alt='Leonidas'
                src={logo}
                className="d-inline-block align-top leonidas shop-brand-logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-2rem">

                {!isLoading && categories.map((item) => (
                  <Link key={item.id} to={`/shop/${item.slug}`} className='nav-link text-upper clr-light shop-navbar-item ff-ubuntu-reg fs-17px'>{item.title}</Link>
                ))}

                <Link to="/shop/cartes-cadeau" className='nav-link text-upper clr-light shop-navbar-item ff-ubuntu-reg fs-17px'>Cartes cadeau</Link>
                <Link to="/shop/tous-les-produits" className='nav-link text-upper clr-light shop-navbar-item ff-ubuntu-reg fs-17px'>Tous les produits</Link>

              </Nav>
            </Navbar.Collapse>
          </Navbar >
        </Container >
      </header>


    </>
  );
};

export default ShopNavbar;