import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { ProductFilterProps } from '@helpers/Props'
import { Slider } from '@mui/material'
import Icons from '@helpers/IconsHelper'

const ProductFilter: FC<ProductFilterProps> = ({
    isFilter,
    windowWidth,
    sortBy,
    categories,
    filteredPrice,
    maxPrice,
    url,
    setIsFilter,
    setIsGrid,
    setProductCategory,
    handlePriceChange,
    valueText,
    handleSortChange,
}) => {

    const [isFilVisible, setIsFilVisible] = useState(true)

    return (
        <div className={`container ${isFilter && windowWidth < 768 ? '' : 'mt-0'}`}>
            <div className={`row ${isFilter && windowWidth < 768 ? 'dnone' : ''}`}>

                {/* Start Result Filter */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 ">
                    <div className="filter-title filter-sort">
                        <span className='filter-result' >Filtrer le résultat:</span>
                        <select value={sortBy} className='form-control select-option' onChange={handleSortChange}>
                            <option value="default">Défaut</option>
                            <option value="prix-croissant">Prix croissant</option>
                            <option value="prix-decroissant">Prix décroissant</option>
                            <option value="date-asc">Ancienneté</option>
                            <option value="date-desc">Nouveauté</option>
                        </select>
                    </div>
                    <hr className='hr-tag mt-3' />
                </div>
                {/* End Result Filter */}

                {/* Start Categories Filter */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 d-none ">
                    <div className="filter-title filter-categories c-pointer" >
                        <div onClick={() => setIsFilVisible(!isFilVisible)} >CATÉGORIES <Icons.MdExpandMore className='clr-dark fs-px-25' /></div>
                        <div className={`fil ${isFilVisible ? '' : 'hidden'}`}>
                            <div className="accordion" id="accordionExample">
                                {categories.map((item, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${index}`}
                                                aria-expanded="false"
                                                aria-controls={`collapse${index}`}
                                            >
                                                {item.title}
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${index}`}
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {item.subCategory && (
                                                    <ul className="list-unstyled mb-0">
                                                        {item.subCategory.map((subitem, subindex) => (
                                                            <li key={subindex} onClick={() => {
                                                                setIsFilter(true)
                                                                setProductCategory(subitem.id)
                                                            }}>
                                                                <Link className='nav-link'
                                                                    to={`/boutique${url}/categorie/${item.slug}/${subitem.slug}`}>
                                                                    {subitem.title}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr className='hr-tag mt-3' />
                </div>
                {/* End Categories Filter */}

                {/* Start Price Filter */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-12 d-none ">
                    <div className="filter-title filter-price">
                        FILTRER PAR TARIF
                    </div>
                    <div className="price-filter mt-4">
                        <div className="row">
                            <div className="col-12">
                                <Slider
                                    getAriaLabel={() => 'Temperature range'}
                                    value={filteredPrice}
                                    onChange={handlePriceChange}
                                    valueLabelDisplay="auto"
                                    color="secondary"
                                    getAriaValueText={valueText}
                                    max={maxPrice}
                                    min={10}
                                />
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="price-values">
                                            <span className='filter-price-value'>PRIX : {filteredPrice[0]} DH <Icons.HiOutlineMinusSm /> {filteredPrice[1]} DH</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Price Filter */}

            </div>

            <div className={`row d-none ${isFilter ? 'm-filter m-filter-rowx' : 'm-filter-row-active'} ${windowWidth > 768 ? 'd-none' : ''}`}>
                {!isFilter ? <hr className='hr-tag mt-3' /> : ''}
                <div className={`col-6 text-start ${isFilter ? 'm-filter-icon' : 'm-filter-size-active'}`}>
                    <button className='btn bm-small-btn bm-danger-btn me-2' onClick={() => setIsFilter(!isFilter)}>
                        <Icons.FaFilter className='bm-icon bm-danger-icon' />
                    </button>
                </div>

                <div className={`col-6 text-end fix-width ${windowWidth > 576 ? 'd-none' : ''} ${isFilter ? '' : 'm-grid-filter'}`}>
                    <button className='btn bm-small-btn bm-primary-btn me-2 ' onClick={() => setIsGrid(false)}>
                        <Icons.CiGrid2H className='bm-icon bm-primary-icon' />
                    </button>
                    <button className='btn bm-small-btn bm-primary-btn me-2' onClick={() => setIsGrid(true)}>
                        <Icons.BsGridFill className='bm-icon bm-primary-icon' />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProductFilter