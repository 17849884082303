import React, { FC, useState } from 'react'
import './ShareMoments.css'

import img1 from '@assets/images/share/Romantic-gift.png'
import img2 from '@assets/images/share/birth-gift.jpg'
import img3 from '@assets/images/share/birthday-gift.jpg'
import img4 from '@assets/images/share/invitation.png'

import RomanticGiftSvg from '@assets/images/moments/moments-svg/gift-heart.svg'
import BirthGiftSvg from '@assets/images/moments/moments-svg/gift-birthday.svg'
import BirthdayGiftSvg from '@assets/images/moments/moments-svg/birthday.svg'
import InvitationSvg from '@assets/images/moments/moments-svg/invitation.svg'

import Icons from '@helpers/IconsHelper'
import { SubmitButton } from '@helpers/Index'
import ReactLink from '@components/ReactLink/ReactLink'
import { Link } from 'react-router-dom'

const ShareMoments: FC = () => {
    const [isImage1, setIsImage1] = useState(true)
    const [isImage2, setIsImage2] = useState(false)
    const [isImage3, setIsImage3] = useState(false)
    const [isImage4, setIsImage4] = useState(false)
    const [all, setAll] = useState(false)


    return (
        <div className='share-moments mt-10rem pb-3rem trans'>
            <div className="row">
                <div className="col-lg-6 col-12 ox-hidden">
                    <img src={img1} alt="" className={`h-90 w-100 object-fit-cover trans ${isImage1 ? 'd-block' : 'd-none'}`} />
                    <img src={img2} alt="" className={`h-90 w-100 object-fit-cover trans ${isImage2 ? 'd-block' : 'd-none'}`} />
                    <img src={img3} alt="" className={`h-90 w-100 object-fit-cover trans ${isImage3 ? 'd-block' : 'd-none'}`} />
                    <img src={img4} alt="" className={`h-90 w-100 object-fit-cover trans ${isImage4 ? 'd-block' : 'd-none'}`} />
                </div>
                <div className="col-lg-6 col-12 px-5 pt-5">
                    <div className="row">
                        <p className='clr-blue d-flex-tart flexwrap  '>
                            <span className='fw-600 fs-55px'>Partagez des </span>
                            <span className='clr-yellow ff-satisfy fs-60px mt-n-17px'> moments de bonheur</span>
                        </p>
                    </div>

                    <div className={`row c-pointer mt-5 ${isImage1 ? 'right5rem position-relative trans' : ''}`}>
                        <div className="col-1 pe-0 me-0 text-end ">
                            {/* <Icons.GrFavorite className={`clr-blue fs-30px mt-1 ${isImage1 ? 'clr-yellow' : ''}`} /> */}
                            <img src={RomanticGiftSvg} alt='Cadeau romantique' className='w-55px' />
                        </div>
                        <div className="col-11">
                            <Link to='/moments-de-bonheur/cadeau-romantique' className='bm-link clr-blue c-pointer ff-ubuntu-m fw-600 fs-31px' onMouseEnter={() => {
                                setIsImage1(true)
                                setIsImage2(false)
                                setIsImage3(false)
                                setIsImage4(false)
                            }}>Cadeau romantique</Link>
                            <p className='fs-20px max-w-32rem'>Dites-le avec le coeur... et les meilleurs chocolats !</p>
                        </div>
                    </div>

                    <div className={`row c-pointer ${isImage2 ? 'right5rem position-relative trans' : ''}`}>
                        <div className="col-1 pe-0 me-0 text-end ">
                            {/* <Icons.GrFavorite className={`clr-blue fs-30px mt-1 ${isImage2 ? 'clr-yellow' : ''}`} /> */}
                            <img src={BirthdayGiftSvg} alt='Cadeau de naissance' className='w-55px' />
                        </div>
                        <div className="col-11">
                            <Link to='/moments-de-bonheur/cadeau-de-naissance' className='bm-link clr-blue c-pointer ff-ubuntu-m fw-600 fs-31px' onMouseEnter={() => {
                                setIsImage1(false)
                                setIsImage2(true)
                                setIsImage3(false)
                                setIsImage4(false)
                            }}>Cadeau de naissance</Link>
                            <p className='fs-20px max-w-32rem'>Une nouvelle vie ? Félicitez les proches avec un cadeau chocolaté.</p>
                        </div>
                    </div>

                    <div className={`row c-pointer ${isImage3 ? 'right5rem position-relative trans' : ''}`}>
                        <div className="col-1 pe-0 me-0 text-end ">
                            {/* <Icons.GrFavorite className={`clr-blue fs-30px mt-1 ${isImage3 ? 'clr-yellow' : ''}`} /> */}
                            <img src={BirthGiftSvg} alt={`Cadeau d'anniversaire`} className='w-55px' />
                        </div>
                        <div className="col-11">
                            <Link to='/moments-de-bonheur/cadeau-anniversaire' className='bm-link clr-blue c-pointer ff-ubuntu-m fw-600 fs-31px' onMouseEnter={() => {
                                setIsImage1(false)
                                setIsImage2(false)
                                setIsImage3(true)
                                setIsImage4(false)
                            }}>Cadeau d'anniversaire</Link>
                            <p className='fs-20px max-w-32rem'>Les chocolats s’invitent à la fête! Faites plaisir à tous les coups avec Leonidas.</p>
                        </div>
                    </div>

                    <div className={`row c-pointer ${isImage4 ? 'right5rem position-relative trans' : ''}`}>
                        <div className="col-1 pe-0 me-0 text-end ">
                            {/* <Icons.GrFavorite className={`clr-blue fs-30px mt-1 ${isImage4 ? 'clr-yellow' : ''}`} /> */}
                            <img src={InvitationSvg} alt='Invitation' className='w-55px' />
                        </div>
                        <div className="col-11">
                            <Link to='/moments-de-bonheur/invitation' className='bm-link clr-blue c-pointer ff-ubuntu-m fw-600 fs-31px' onMouseEnter={() => {
                                setIsImage1(false)
                                setIsImage2(false)
                                setIsImage3(false)
                                setIsImage4(true)
                            }}>Invitation</Link>
                            <p className='fs-20px max-w-32rem'>Un dîner en famille? Une soirée entre amis? Remerciez délicieusement ceux qui vous accueillent.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 pe-0 me-0 text-start">
                            <ReactLink
                                to='/moments-de-bonheur'
                                label='Découvrir tous les moments'
                                className='py-13px submit-btn max-w-19rem'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareMoments