import './App.css';
import './assets/sass/App.scss';
import "react-toastify/dist/ReactToastify.css";
import { WindowWidthProvider } from './app/contexts/WindowWidthContext';
import { Routing } from './app/routing/Routing';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { BackToTop } from './app/components/BackToTop/BackToTop';
import { CategoriesProvider } from './app/contexts/CategoriesContext';
import { ShoppingCartProvider } from './app/contexts/ShoppingCartContext';
import { ShoppingCartDataProvider } from './app/contexts/ShoppingCartDataContext';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <WindowWidthProvider>
        <CategoriesProvider>
          <ShoppingCartProvider>
            <ShoppingCartDataProvider>
              <HelmetProvider>
                <ToastContainer limit={5} />
                <Routing />
                <BackToTop />
              </HelmetProvider>
            </ShoppingCartDataProvider>
          </ShoppingCartProvider>
        </CategoriesProvider>
      </WindowWidthProvider>
    </QueryClientProvider>
  );
}

export default App;