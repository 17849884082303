import { FC, Fragment, useEffect, useState } from 'react'
import { useApiRequest } from '@helpers/ApiRequest';
import { formatDatex, formatPrice, uploadUrl } from '@helpers/Helpers';
import { useNavigate } from 'react-router-dom';

const CmiFailPage: FC = () => {

    const apiRequest = useApiRequest();
    const storedOrderInfo = localStorage.getItem('orderInfo');
    const [orderInfo, setOrderInfo] = useState<any>();
    const [order, setOrder] = useState<any>();
    const [orderItems, setOrderItems] = useState<any[]>([]);
    const navigate = useNavigate();

    const goToOrdersPage = () => {
        navigate('/shop/my-account/orders')
    }
    useEffect(() => {
        if (storedOrderInfo) {
            setOrderInfo(JSON.parse(storedOrderInfo));
        }
    }, []);


    const fetchData = async () => {
        if (orderInfo?.reference) {
            const response = await apiRequest({
                route: `order-by-ref/${orderInfo?.reference}`,
                method: 'GET',
            });

            if (response.status === 200) {
                setOrder(response.data)
                setOrderItems(response.data.orderItems)

            }
        }
    }


    useEffect(() => {
        fetchData()
    }, [orderInfo?.reference])


    return (
        <Fragment>
            <div className="container mt-5 d-non">
                <div className="row d-flex-center">
                    <div className="col-12 fw-600 top-text-danger text-center">
                        <span className='text-center ff-varela-round '>Votre paiement a échoué! Merci de rééssayer plus tard</span>
                    </div>

                </div>

                {
                    orderItems.length > 0 ?
                        <>
                            <div className="row ">
                                <div className="col-6 d-fle x justify-content-end">
                                    <p className='pb-1'>
                                        <span className='fw-bold ff-varela-round clr-gray'>Numero de commande : </span>
                                        <span className=''>{order?.reference} </span>
                                    </p>
                                    <p className='pb-1'>
                                        <span className='fw-bold ff-varela-round clr-gray'>Date Commande : </span>
                                        <span className=''>{formatDatex(order?.dateCreate)}</span>
                                    </p>
                                    <p className='pb-1'>
                                        <span className='fw-bold ff-varela-round clr-gray'>Methode de payemet : </span>
                                        <span className=''>{order?.paymentMethod === 'livraison' ? 'À la livraison' : 'Par Carte bancaire'}</span>
                                    </p>
                                </div>
                            </div>
                            <hr className='hr-tag' />

                            <div className="row">
                                <div className="col-12 ">
                                    <div className=" mt-">
                                        <p className='fw-bold ff-varela-round fs-4'><span>Informations de Client :</span></p>
                                        <p className='p-1'>
                                            <span className='fw-bold ff-varela-round clr-gray'>Nom : </span>
                                            <span className=''>{`${order?.user.prenom} ${order?.user.nom}`}</span>
                                        </p>
                                        <p className='p-1'>
                                            <span className='fw-bold ff-varela-round clr-gray'>Telephone : </span>
                                            <span className=''>{order?.user.telephone}</span>
                                        </p>
                                        {
                                            order?.livraisonAdresse.email ?
                                                <p className='p-1'>
                                                    <span className='fw-bold ff-varela-round clr-gray'>Email : </span>
                                                    <span className=''>{order?.livraisonAdresse.email}</span>
                                                </p>
                                                :
                                                ''
                                        }
                                        <p className='p-1'>
                                            <span className='fw-bold ff-varela-round clr-gray'>Adresses de facturation : </span>
                                            <span className=''>{`${order?.facturationAdresse?.rueInfo}, ${order?.facturationAdresse?.ville}, ${order?.facturationAdresse?.region} | ${order?.facturationAdresse?.codepostal}`}</span>
                                        </p>
                                        <p className='p-1'>
                                            <span className='fw-bold ff-varela-round clr-gray'>Adresses de livraison : </span>
                                            <span className=''>{`${order?.livraisonAdresse?.rueInfo}, ${order?.livraisonAdresse?.ville}, ${order?.livraisonAdresse?.region} | ${order?.livraisonAdresse?.codepostal}`}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className='hr-tag' />

                            <div className="row mb-5">
                                <div className="col-12">
                                    <div className="account-order">

                                        {
                                            orderItems.length > 0 ?

                                                <div className="order-content edit-form">

                                                    <table className='table align-middle gs-0 gy-4'>
                                                        <thead>
                                                            <tr className='fwbold text-muted bg-light'>
                                                                <th className=' rounded-start'>Image</th>
                                                                <th className=' rounded-start'>Nom Produit/Pack</th>
                                                                <th className='text-center'>Quantité</th>
                                                                <th className='text-center'>Prix</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {
                                                                orderItems.map((item, index) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <div className='symbol symbol-50px'>
                                                                                        <img
                                                                                            src={`${uploadUrl}/${item.product
                                                                                                ?
                                                                                                `product_images/${item.product?.image}`
                                                                                                :
                                                                                                `pack_images/${item.pack?.image}`}`}
                                                                                            className='order-details-img'
                                                                                            alt={item.product?.title}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td className='td-min-w'>
                                                                                <span className='ff-varela-round text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                                    {`${item.product ? item.product?.title : item.pack?.title}`}
                                                                                </span>
                                                                            </td>

                                                                            <td className='text-center'>
                                                                                <span className='ff-varela-round clr-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                                    {item.quantity}
                                                                                </span>
                                                                            </td>

                                                                            <td className='text-center'>
                                                                                <span className='ff-varela-round clr-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                                                    {item.product ? item.product?.sellingPrice : item.pack?.sellingPrice} DH
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })

                                                            }
                                                        </tbody>
                                                    </table>

                                                </div>
                                                :
                                                ''
                                        }


                                    </div>

                                </div>
                            </div>
                            <hr className='hr-tag' />

                            <div className="row">
                                <div className="col-12 d-flex justify-content-start">
                                    <p className='p-w'>
                                        <span className='fs-5 ff-varela-round'>Total : </span>
                                        <span className='ff-varela-round fw-bold'>{formatPrice(order?.totalPrice)} MAD</span>
                                    </p>
                                </div>

                                <div className="col-12 d-flex justify-content-start">
                                    <p className='p-w'>
                                        <span className='fs-5 ff-varela-round'>Frait de livraison : </span>
                                        <span className='ff-varela-round fw-bold'>{Number(order?.totalPrice) > 300 ? 'Gratuite' : '50 MAD'}</span>
                                    </p>
                                </div>

                                <div className="col-12 d-flex justify-content-start">
                                    <p className='p-w bm-total-price-p'>
                                        <span className='fs-5 ff-varela-round bm-total-price'>Total à payer  : </span>
                                        <span className='ff-varela-round fw-bold'>{Number(order?.totalPrice) > 300 ? formatPrice(String(order?.totalPrice)) : formatPrice(String(order?.totalPrice + 50))} MAD</span>
                                    </p>
                                </div>

                            </div>
                            <hr className='hr-tag' />

                            <div className="row mb-5">
                                <div className="text-center text-light">
                                    <button className="btn-error mt-5" onClick={goToOrdersPage} >
                                        Afficher mes commandes
                                    </button>
                                </div>
                            </div>


                        </>
                        :

                        ''
                }
            </div>


        </Fragment>
    )
}


export default CmiFailPage