// import { StylesConfig } from 'react-select';
// import chroma from 'chroma-js';

export interface ColourOption {
  value: string;
  label: string;
  color: string;
  isFixed?: boolean;
  isDisabled?: boolean;
}

export const colourOptions: ColourOption[] = [
  { value: 'ocean', label: 'Océan', color: '#00B8D9' },
  { value: 'blue', label: 'Bleu', color: '#0052CC' },
  { value: 'purple', label: 'Violet', color: '#5243AA' },
  { value: 'red', label: 'Rouge', color: '#FF5630' },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Jaune', color: '#FFC400' },
  { value: 'green', label: 'Vert', color: '#36B37E' },
  { value: 'forest', label: 'Forêt', color: '#00875A' },
  { value: 'slate', label: 'Ardoise', color: '#253858' },
  { value: 'silver', label: 'Argent', color: '#666666' },
  { value: 'brown', label: 'Marron', color: '#8B4513' },
  { value: 'golden', label: 'Doré', color: '#FFD700' }
]


export interface FlavourOption {
  value: string;
  label: string;
  rating: string;
}

export const flavourOptions: FlavourOption[] = [
  { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
  { value: 'chocolate', label: 'Chocolate', rating: 'good' },
  { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
  { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' },
];

export interface StateOption {
  value: string;
  label: string;
}


// export const colourStyles: StylesConfig<ColourOption, true> = {
//   control: (styles) => ({ ...styles, backgroundColor: 'white' }),
//   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
//     const color = chroma(data.color);
//     return {
//       ...styles,
//       backgroundColor: isDisabled
//         ? undefined
//         : isSelected
//           ? data.color
//           : isFocused
//             ? color.alpha(0.1).css()
//             : undefined,
//       color: isDisabled
//         ? '#ccc'
//         : isSelected
//           ? chroma.contrast(color, 'white') > 2
//             ? 'white'
//             : 'black'
//           : data.color,
//       cursor: isDisabled ? 'not-allowed' : 'default',

//       ':active': {
//         ...styles[':active'],
//         backgroundColor: !isDisabled
//           ? isSelected
//             ? data.color
//             : color.alpha(0.3).css()
//           : undefined,
//       },
//     };
//   },
//   multiValue: (styles, { data }) => {
//     const color = chroma(data.color);
//     return {
//       ...styles,
//       backgroundColor: color.alpha(0.1).css(),
//     };
//   },
//   multiValueLabel: (styles, { data }) => ({
//     ...styles,
//     color: data.color,
//   }),
//   multiValueRemove: (styles, { data }) => ({
//     ...styles,
//     color: data.color,
//     ':hover': {
//       backgroundColor: data.color,
//       color: 'white',
//     },
//   }),
// };
