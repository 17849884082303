import React, { useEffect, useState } from 'react'
import { useApiRequest } from '@helpers/ApiRequest';
import { Helmet } from 'react-helmet-async';
import AOS from 'aos';
import BlogSwiper from '@components/Carousel/BlogSwiper/BlogSwiper';
import { Footer, HomeNavbar, TopHeader } from '@helpers/Index';
import HomeCarousel from '@components/Carousel/HomeCarousel/HomeCarousel';
import 'aos/dist/aos.css';
import ChristmasSection from '@components/ChristmasSection/ChristmasSection';
import OurChocolates from '@components/OurChocolates/OurChocolates';
import ShareMoments from '@components/ShareMoments/ShareMoments';
import QualitySection from '@components/QualitySection/QualitySection';
import AboutUs from '@components/AboutUs/AboutUs';


interface SliderProps {
    id: number;
    title: string;
    image: string;
}

const Home: React.FC = () => {
    const [sliders, setSliders] = useState<SliderProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const apiRequest = useApiRequest()

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1300);
    }, []);

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: 'sliders.json',
                method: 'GET',
            });

            setSliders(response.data)
        } catch (error) {

        }
    };
    useEffect(() => {
        fetchData();
    }, [])


    useEffect(() => {
        AOS.init();
    }, [])


    return (

        <>
            <Helmet>
                <title>Leonidas | Maître chocolatier - Chocolat et pralines belges</title>
            </Helmet>

            <TopHeader />
            <HomeNavbar />
            <HomeCarousel items={sliders} />

            <ChristmasSection />
            <OurChocolates />
            <ShareMoments />
            <QualitySection />
            <AboutUs />


            {/* <div data-aos="fade-up" data-aos-duration="2000">
            </div>

            <div data-aos="fade-up" data-aos-duration="2000">
                <BlogSwiper />
            </div> */}


            <Footer />
        </>
    )
}

export default Home;