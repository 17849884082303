import { useState, memo, useEffect, FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useShoppingCart } from '@contexts/ShoppingCartContext'
import { useApiRequest } from '@helpers/ApiRequest'
import { PricingProps, ProductProps, VariantProps, colorsProps } from '@helpers/Props'
import { FrCustomeErrorNorify } from '@helpers/Toastify'
import { DecodeData, ReactHelmet, convertStringToArray, productImgUrl, variantImgUrl } from '@helpers/Helpers'
import { SubmitButton, loadingHelper, ingredientsImg, detailsProductBg } from '@helpers/Index'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { colourOptions } from '@data/Colors'
import ProductImagesGallery from '@components/Carousel/ProductImagesGallery/ProductImagesGallery'
import Icons from '@helpers/IconsHelper'
import ShopLayout from '@layouts/ShopLayout'
import OrderByDevisModal from '@components/Shop/components/OrderByDevisModal/OrderByDevisModal'
import './ProductDetailsStyle.css'

const ProductDetails: FC = () => {

    const apiRequest = useApiRequest()
    const { addToCart } = useShoppingCart()
    const { slug } = useParams()
    const { state } = useLocation()
    const { windowWidth } = useWindowWidthContext()
    const [cardItemQuantity, setCardItemQuantity] = useState(1)
    const [discount, setDiscount] = useState(null)
    const [product, setProduct] = useState<ProductProps>()
    const [selectedVariant, setSelectedVariant] = useState<VariantProps>()
    const [selectedColor, setSelectedColor] = useState<colorsProps>()
    const [selectedVariantImage, setSelectedVariantImage] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [description, setDescription] = useState('')
    const [ingredients, setIngredients] = useState('')
    const [variantPrice, setVariantPrice] = useState<number>();
    const [isVariant, setIsVariant] = useState(false);
    const [selectedVariantId, setSelectedVariantId] = useState<number>();
    const [productsColors, setProductsColors] = useState<any[]>([])
    const [showOrderModal, setShowOrderModal] = useState(false)


    const openOrderModal = () => {
        setShowOrderModal(true)
    }

    const hideOrderModal = () => {
        setShowOrderModal(false)
    }


    const imageStyles = 'img-fluid product-details-image d-flex justify-content-center'
    const thumbnailStyle = 'img-fluid product-thumbnail-img w-35 z-index-999  position-absolute mt-n-14rem'

    const fetchProductData = async () => {
        try {
            setIsLoading(true)
            const response = await apiRequest({
                route: `products.json?slug=${slug}`,
                method: 'GET',
            })

            if (response.status === 200) {
                setProduct(response.data[0])

                if (response.data[0].colors && response.data[0].colors !== '') {
                    const colorArray = convertStringToArray(response.data[0].colors)
                    setProductsColors(colourOptions.filter(option => colorArray.includes(option.value)))
                } else {
                    setProductsColors([])
                }
                setIsLoading(false)
            } else {
                FrCustomeErrorNorify()
                setIsLoading(false)
            }

        } catch {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    }

    const fetchPromotionData = async () => {
        try {

            if (product?.id) {
                const response = await apiRequest({
                    route: `promotion-discount/product_id/${product?.id}`,
                    method: 'GET',
                })

                if (response.status === 200) {
                    setDiscount(response.data.discount)
                } else {
                    FrCustomeErrorNorify()
                }
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        if (state) {
            setProduct(state)

            if (state.colors && state.colors !== '') {
                const colorArray = convertStringToArray(state.colors)
                setProductsColors(colourOptions.filter(option => colorArray.includes(option.value)))
            } else {
                setProductsColors([])
            }
        } else {
            fetchProductData()
        }
    }, [])

    useEffect(() => {
        fetchPromotionData()
    }, [product?.id])

    useEffect(() => {
        if (product && product.variant && product.variant?.length > 0) {
            setSelectedVariant(product.variant[0])
            setDescription(product.variant[selectedIndex].description)
            setIngredients(product.variant[selectedIndex].ingredients)
            setSelectedVariantId(product.variant[selectedIndex].id)
            setIsVariant(true)
        }

        if (productsColors.length > 0) {
            setSelectedColor(productsColors[0])
        }
    }, [product])

    useEffect(() => {
        if (discount !== null && product?.variant && product?.variant?.length > 0) {
            let sellingPrice = product?.variant[selectedIndex].sellingPrice

            if (sellingPrice !== product?.sellingPrice) {
                setVariantPrice(sellingPrice - (sellingPrice * (discount / 100)))
            } else {
                setVariantPrice(product?.sellingPrice - (product?.sellingPrice * (discount / 100)))
            }
        } else if (discount === null && product?.variant && product?.variant?.length > 0) {
            let sellingPrice = product?.variant[selectedIndex].sellingPrice

            if (sellingPrice !== product?.sellingPrice) {
                setVariantPrice(sellingPrice)
            } else {
                setVariantPrice(product?.sellingPrice)
            }
        }
    }, [product, selectedIndex, discount])

    const increment = () => {
        setCardItemQuantity(prevItem => prevItem + 1)
    }

    const decrement = () => {
        if (cardItemQuantity > 1) {
            setCardItemQuantity(prevItem => prevItem - 1)
        }
    }

    if (!product) {
        return null
    }

    let productPrice = discount !== null ? (product.sellingPrice - (product.sellingPrice * (discount / 100))) : product.sellingPrice

    const Pricing = ({ price, selling }: PricingProps) => {
        return (
            <>
                <del className='me-2 discount-price mt-3'>{selling?.toFixed(2)} MAD</del>
                <p className="product-details-prix mt-2rem mb-5 clr-orange">{price?.toFixed(2)} MAD</p>
            </>
        )
    }

    return (

        <ShopLayout
            containerTitle={isVariant ? `${product.title} - ${selectedVariant?.size}` : product.title}
            containerBackground={detailsProductBg}
            containerImage={product.variant.length > 0 ? `${variantImgUrl}/${selectedVariant?.image}` : `${productImgUrl}/${product.image}`}
            containerUrl={product.categorie[0].title}
            titleStyle={`fs-${windowWidth > 578 ? '55' : '25'}px max-w-40rem clr-light`}
            haveBreadCrumbs
            haveImage
            haveSecondUrl>

            <ReactHelmet title={`Leonidas - ${product.title}`} />

            {isLoading ?
                <div className="product-details mt-3 mx-3">
                    <loadingHelper.AnimatedLoading label='de produit' />
                </div>
                :
                <div className={`product-details ${windowWidth > 578 ? 'mt-5' : 'mt-7rem'} pt-5 mb-5 ox-hidden`}>
                    <div className="container">
                        <div className="row product-details-content d-flex justify-content-around min-h-25rem">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ps0">
                                <div className="product-details-info ms-0 ps0">

                                    {product.description && product.description !== null && product.variant.length === 0 ?
                                        <DecodeData content={product.description} />
                                        :
                                        null
                                    }

                                    {selectedVariant?.description && selectedVariant?.description !== null ?
                                        <DecodeData content={selectedVariant?.description} />
                                        :
                                        null
                                    }

                                    <div className="product-details-total d-none">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-6 product-details-total-text">
                                                Prix total :
                                            </div>
                                            <div className=" col-lg-6 col-md-6 col-6 product-details-total-price">
                                                {cardItemQuantity > 0 ?
                                                    isVariant && variantPrice ?
                                                        `${(variantPrice * Number(cardItemQuantity)).toFixed(2)} MAD`
                                                        :
                                                        `${(productPrice * Number(cardItemQuantity)).toFixed(2)} MAD`
                                                    :
                                                    isVariant ?
                                                        `${productPrice} MAD`
                                                        :
                                                        `${variantPrice} MAD`
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <hr className='hr-tag' /> */}

                                    {/* --------- Start Variant */}
                                    {product.variant && product.variant?.length > 0 ?
                                        <div className={`row variant-row ${selectedVariant ? 'variant-row-details-m2' : 'variant-row-details-m1'}`}>
                                            <h3 className='ff-ubuntu-m fw-600 clr-blue ps0 mb-1rem'>Choisir le format :</h3>

                                            {product.variant?.map((v: VariantProps, index: number) => {
                                                const sv = v.size
                                                return (
                                                    <div className={`variant-size-content ff-ubuntu-reg fw-600 ${index === 0 ? 'ms-1rem me-1' : 'mx-1'} d-flex-center c-pointer ${selectedVariant?.size === sv ? 'selected-border' : 'notselected-border'}`}
                                                        key={index}
                                                        onClick={() => {
                                                            setSelectedVariant(v)
                                                            setDescription(v.description)
                                                            setIngredients(v.ingredients)
                                                            setSelectedVariantImage(v.variantImages)
                                                            setSelectedIndex(index)
                                                            setSelectedVariantId(v.id)
                                                        }}>
                                                        {v.size}
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                    {/* --------- End Variant */}

                                    {/* --------- Start Colors */}
                                    {productsColors.length > 0 ?
                                        <div className={`row variant-row ms-1 mt-1rem ${selectedColor ? 'variant-row-details-m2' : 'variant-row-details-m1'}`}>
                                            <h3 className='ff-ubuntu-m fw-600 clr-orange ps-0 mb-1'>Choisir le couleur :</h3>

                                            <div className="variant-ref-content pb-1 ps-0">
                                                {selectedColor ?
                                                    <span className='ff-ubuntu-reg clr-blue fs-20px'>{selectedColor?.label}</span>
                                                    :
                                                    null}
                                            </div>
                                            {productsColors.map((color: colorsProps, index: number) => {
                                                const sv = color
                                                return (
                                                    <div className={`variant-icon-content mx-1 d-flex-center c-pointer ${selectedColor === sv ? 'enabled-color' : 'disabled-color'}`}
                                                        key={color.label}
                                                        onClick={() => {
                                                            setSelectedColor(color)
                                                        }}>
                                                        <div className="variant-icon" style={{ backgroundColor: `${color.color}` }}>
                                                            {/* <img src={`${variantIconUrl}/${color.icon}`} alt="" className='variant-icon-img' /> */}
                                                            {/* <span style={{ backgroundColor: `${color.color}` }}></span> */}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        :
                                        null
                                    }
                                    {/* --------- End Colors */}


                                    {/* --------- Start Price */}
                                    {product.byDevis === 'enable' ?
                                        <p className="product-details-prix mt-2rem mb-4">
                                            <p className='clr-blue text-cap ff-ubuntu-reg fs-20px pe-5px mb-0'>à partir de :</p>
                                            <p className='clr-orange fw-600 ff-lato-reg fs-23px'>{product.sellingPrice} MAD</p>
                                        </p>
                                        : productPrice !== product.sellingPrice
                                            ? isVariant ?
                                                <Pricing price={variantPrice} selling={product.variant[selectedIndex].sellingPrice} />
                                                :
                                                <Pricing price={productPrice} selling={product.sellingPrice} />
                                            : isVariant ?
                                                <p className="product-details-prix mt-2rem mb-4 clr-orange ff-lato-reg fs-23px">{variantPrice} MAD</p>
                                                :
                                                <p className="product-details-prix mt-2rem mb-4 clr-orange ff-lato-reg fs-23px">{productPrice} MAD</p>
                                    }
                                    {/* --------- End Price */}


                                    {/* --------- Start Add To Cart */}
                                    <div className="row align-items-center mt-2">

                                        <div className="col-12 ps0 mb-10px">
                                            <div className={`col-md-4 col-lg-4 col-xl-4 col-12 ${product.byDevis === 'enable' ? 'd-none' : ''} ${windowWidth < 578 ? 'mb-2' : ''}`}>
                                                <div className="radius-10px shadow-1 py-12px me-0px max-w-13rem d-flex-center text-center">
                                                    <span className="fs-20px" onClick={() => decrement()}>
                                                        <Icons.HiMinusSm className='shopping-card-item-quantity-icon fs-30px' />
                                                    </span>
                                                    <span className="px-25px fs-18px">{cardItemQuantity}</span>
                                                    <span className="fs-20px" onClick={() => increment()}>
                                                        <Icons.BiPlus className='shopping-card-item-quantity-icon fs-30px' />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 ps0">
                                            <SubmitButton
                                                btnLabel={`${product.byDevis === 'enable' ? 'Commander' : 'Ajouter au panier'} `}
                                                onClick={() => {
                                                    if (product.byDevis === 'enable') {
                                                        openOrderModal()
                                                    } else {
                                                        const variantId = selectedVariantId ? selectedVariantId : '';
                                                        if (product.variant && product.variant?.length > 0) {

                                                            addToCart(product.id, isVariant ? Number(variantPrice) : productPrice, 'product', variantId, cardItemQuantity, product.sellingPrice, true);
                                                        } else {
                                                            addToCart(product.id, isVariant ? Number(variantPrice) : productPrice, 'product', '', cardItemQuantity, product.sellingPrice);
                                                        }
                                                    }
                                                }}
                                                className="mt-1 py-1rem px-2rem submit-btn-1" />
                                        </div>
                                    </div>
                                    {/* --------- End Add To Cart */}

                                </div>
                            </div>

                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                                {/* <div className={`product-thumbnail ${product.productImages.length < 1 ? 'd-flex justify-content-center' : ''}`}>
                                    {product.variant && product.variant.length > 0 ?
                                        <img src={`${variantImgUrl}/${product.variant[selectedIndex].image}`}
                                            alt={product.variant[selectedIndex].label}
                                            className={thumbnailStyle} />
                                        :
                                        <img src={`${productImgUrl}/${product.image}`}
                                            alt={product.title}
                                            className={thumbnailStyle} />
                                    }
                                </div> */}

                                <div className={`product-image-gallery d-none ${product.productImages.length < 1 ? 'd-flex justify-content-center' : ''}`}>
                                    {product.productImages && product.productImages.length > 0 && selectedVariantImage.length === 0 ?
                                        <ProductImagesGallery product={product} />
                                        : selectedVariantImage && product.variant && product.variant.length > 0 ?
                                            product.variant[selectedIndex].variantImages && product.variant[selectedIndex].variantImages.length > 0
                                                ?
                                                <ProductImagesGallery product={product} isVariant={true} index={selectedIndex} />
                                                :
                                                <img src={`${variantImgUrl}/${product.variant[selectedIndex].image}`}
                                                    alt={product.variant[selectedIndex].label}
                                                    className={imageStyles} />
                                            :
                                            <img src={`${productImgUrl}/${product.image}`}
                                                alt={product.title}
                                                className={imageStyles} />
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="ingredients-container">
                        <div className="row d-flex-between pe-2rem">
                            <div className="col-lg-4 col-12">
                                <div className="ingredients-image w-100">
                                    <img src={ingredientsImg} alt="ingredients" className='w-100 h-100 ingredients-img' />
                                </div>
                            </div>
                            <div className="col-lg-8 col-12 pe-10px">
                                <h2 className='clr-blue ff-ubuntu-m fw-600'>Contenu & Ingrédients</h2>
                                <p className='clr-orange fw-600 ff-ubuntu-reg '>{product.title} {isVariant ? ` - ${selectedVariant?.size}` : ''}</p>

                                {product.ingredients && product.ingredients !== null ?
                                    ingredients ?
                                        <DecodeData content={ingredients} />
                                        :
                                        <DecodeData content={product.ingredients} />
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>

                </div >
            }
            <OrderByDevisModal
                show={showOrderModal}
                onHide={hideOrderModal}
                product={product}
                variant={selectedVariantId}
                isvariant={isVariant}
                color={selectedColor?.label} />
        </ShopLayout>
    )
}

export default memo(ProductDetails)