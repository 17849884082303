import React, { FC } from 'react'
import './ChristmasSection.css'
import { SubmitButton, christmasImage } from '@helpers/Index'

const ChristmasSection: FC = () => {
    return (
        <div className='christmas-section w-100 px-0 mx-0'>
            <div className="containerd">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="christmas-section-image">
                            <img src={christmasImage} alt="" className='christmas-section-img w-100 ' />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 row d-flex ai-center ">
                        <div className="row d-flex ai-center">
                            <div className="col-12 mb-3">
                                <h1 className='ff-lato-reg fs-55px max-w-40rem'>
                                    Chocolate for a magical Christmas!
                                </h1>
                            </div>

                            <div className="col-12">
                                <p>
                                    Christmas is fast approaching! Nothing could be better than offering delicious Leonidas chocolates to melt your loved ones’ taste buds. Taste our Christmas range to share a moment of magic during the holiday season.
                                </p>
                                <p>
                                    Don’t wait any longer to taste the little Christmas trees or Christmas balls!
                                </p>
                            </div>

                            <div className="col-12 h-5rem d-flex-start">
                                <div className="col-6">
                                    <SubmitButton
                                        btnLabel='Discover the Christmas range'
                                        className='py-13px submit-btn'
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChristmasSection