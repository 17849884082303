import React, { FC } from 'react'
import './LoadingWithDots.css'

const LoadingWithDots: FC = () => {
    return (
        <div className="custom-loader mb-5"></div>
    )
}

export default LoadingWithDots
