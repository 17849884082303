import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import './FooterStyle.css'
import Icons from '@helpers/IconsHelper';
import cmi from '@assets/images/footer/cmi.png'
import visa from '@assets/images/footer/verified_by_visa.png'
import master from '@assets/images/footer/securecode.png'
import { useWindowWidthContext } from '@contexts/WindowWidthContext';

const Footer: FC = () => {

  const { windowWidth } = useWindowWidthContext()

  return (
    <>
      <MDBFooter className='text-center text-lg-start bg-footer ff-lato-reg clr-light ff-lato-reg00'>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt5 pt-2'>
            <MDBRow className='mt-3'>
              <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
                © {new Date().getFullYear()} Copyright
                <Link className='text-reset ps-1' to='/'>
                  Leonidas
                </Link>
              </MDBCol>

              <MDBCol md='4' lg='4' xl='4' className='mx-auto mb-4'>
                Leonidas sur les réseaux sociaux::
                <Icons.FaFacebookF className='mx-1' />
                <Icons.RiInstagramFill className='mx-1' />
                <Icons.FaYoutube className='mx-1' />
              </MDBCol>

              <div className={`col-lg-3 col-md-4 col-12 text-center ${windowWidth > 810 ? '' : 'pb-4'}`}>
                <div className="footer-images d-flex-center">
                  <img src={cmi} alt="cmi" className={`h-100 w-80px px-2 ${windowWidth > 810 ? '' : 'px-3'}`} />
                  <img src={master} alt="cmi" className={`h-100 w-80px px-2 ${windowWidth > 810 ? '' : 'px-3'}`} />
                  <img src={visa} alt="cmi" className={`h-100 w-80px px-2 ${windowWidth > 810 ? '' : 'px-3'}`} />
                </div>
              </div>

            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </>
  );
};

export default Footer;