import { FC } from 'react'
import './AboutUs.css'
import img1 from '../../../assets/images/chocolat/logo-footer-en.png'
import { Link } from 'react-router-dom'

const AboutUs: FC = () => {

    const less = '>'
    return (
        <div className='about-us'>
            <div className="row">
                <div className="col-8">
                    <h1 className='clr-blue fw-600 mb-5'>A PROPOS DE LEONIDAS</h1>
                    <p className='clr-blue pe-5'>
                        Depuis sa création en 1913, le chocolatier belge Leonidas a toujours eu pour mission de créer de véritables moments de bonheur en rendant le luxe du chocolat accessible à tous. Avec plus de 1200 points de vente dans 40 pays, Leonidas s’est au fil du temps forgé une réputation que beaucoup envient : celle de la marque de pralines préférée des Belges. A l’échelon mondial également, Leonidas est reconnu pour ses chocolats de la plus haute qualité et pour son savoir-faire. Les maîtres Chocolatiers créent sans cesse de nouvelles recettes et de nouveaux produits de saison qui prennent place à côté d’un vaste assortiment de pralines. Leonidas travaille exclusivement du chocolat 100% pur beurre de cacao, sans huile de palme et du cacao durable. En 2023, Leonidas fête son 110e anniversaire. 110 ans d’amour pour ses pralines et ses consommateurs… et des millions de moments de bonheur créés depuis 1913 grâce aux chocolats Leonidas.
                    </p>
                </div>

                <div className="col-4 text-end">
                    <img src={img1} alt="" className='w-18rem' />
                </div>

                <span className='tag-hr px-3 mt-5' ></span>
            </div>

            <div className="row mt-4">
                <div className="col-4">
                    <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper fw-600'>shops</span> </p>
                    <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper fw-600'>chocoloates</span> </p>
                </div>
                <div className="col-4">
                    <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper fw-600'>GOURMET DELIGHTS</span> </p>
                    <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper fw-600'>BLISSFUL MOMENTS</span> </p>
                </div>
                <div className="col-4">
                    <Link to='/shop/conditions-generales-de-vente' className='nav-link'><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper'>Condition générales de vente</span> </Link>
                    <Link to='/shop/conditions-generales-d-utilisation' className='nav-link'><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper'> Conditions Générales D’utilisation</span> </Link>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
