import { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Icons from '@helpers/IconsHelper'
import './ShopTopHeader.css'
import SearchBox from '../../../SearchBox/SearchBox'
import {  emptyCart, hooks } from '@helpers/Index'
import { ProductProps } from '@helpers/Props'
import { useApiRequest } from '@helpers/ApiRequest'
import { useShoppingCart } from '../../../../contexts/ShoppingCartContext'
import { SubShoppingCart } from '../../../Cart/SubShoppingCart/SubShoppingCart'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'

const ShopTopHeader: FC = () => {

    const { windowWidth } = useWindowWidthContext()
    const { cartItems, cartQuantity, total, emptyShoppingCart, updateCartItemsWithPromotions } = useShoppingCart()
    const cartItemsQuantity = cartItems.length
    const apiRequest = useApiRequest()
    const { productIds, isProductIds } = hooks.useProductIds()

    // States
    const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false)
    const [searchBoxVisible, setSearchBoxVisible] = useState(false)
    const [products, setProducts] = useState<ProductProps[]>([])
    const [textValue, setTextValue] = useState('')

    // Use Click Outside
    const elementRef = useRef<HTMLDivElement>(null)
    const blockRef = useRef<HTMLDivElement>(null)

    const handleShoppingCartBoxClose = () => {
        setShoppingCartBoxVisible(false)
    }

    hooks.useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef)

    const handleShoppingCartIconClick = () => {
        setShoppingCartBoxVisible(!ShoppingCartBoxVisible)
    }

    useEffect(() => {
        updateCartItemsWithPromotions()
    }, [ShoppingCartBoxVisible])


    useEffect(() => {
        if (!searchBoxVisible) {
            setProducts([])
            setTextValue('')
        }
    }, [searchBoxVisible])

    const handleSearchIconClick = () => {
        setSearchBoxVisible(!searchBoxVisible)
    }

    const searchProducts = async (text: string) => {
        try {
            const response = await apiRequest({
                route: `products.json?title=${text}&disponibilite=enstock`,
                method: 'GET',
            })

            if (response.status === 200) {
                setProducts(response.data)
            } else {
            }

        } catch (error) {
        }
    }

    return (
        <div className='shop-top-header-container d-flex-end'>
            <div className="containerr top-container w-100 pe-2rem">
                <div className="row d-flex-end mx-6rem">
                    <div className="col-8 text-end z-index-999">

                        <div onClick={handleSearchIconClick} className='d-inline px-15px py-5px mx-1rem'>
                            <Icons.BsSearch className="shop-top-header-icon fs-22px clr-light me" />
                        </div>

                        <Link to='/shop/login' className='nav-link d-inline px-15px py-5px raduis-10px mx-1rem bg-icon'>
                            <Icons.FaUser className="shop-top-header-icon fs-18px clr-dark me" /><span className='clr-dark ps-5px'>Compte</span>
                        </Link>

                        <div className="d-inline px-15px py-5px raduis-10px mx-1rem bg-icon" onClick={handleShoppingCartIconClick} ref={blockRef}>
                            <Icons.MdShoppingCart className="shop-top-header-icon fs-18px clr-dark" /> <span className='clr-dark ps-5px'>Panier</span>
                            <span className="position-absolute translate-middle badge card-badge ms-10px rounded-circle z-index-9 bg-danger badge-card">
                                {cartItemsQuantity}
                            </span>
                        </div>

                    </div>
                </div>
            </div>

            {/* Start shoppingCart box */}
            <div ref={elementRef} className={`subshopping-card-box ${ShoppingCartBoxVisible ? 'show' : 'd-nones'}`}>
                <div className="container">
                    <div className="row card-content">
                        <div className="col-md-12 col-12 mb-3">
                            {cartQuantity < 1 ?
                                <>
                                    <div className="row">
                                        <div className="col-12 d-flex-center col-">
                                            <img src={emptyCart} alt="empty cart" className='subshopping-card-empty-image' />
                                        </div>
                                        <div className="col-12 mt-3 d-flex-center col-">
                                            <h4 className='text-center'>Votre panier est actuellement vide.</h4>
                                        </div>
                                        <div className="col-12 mt-3 d-flex-center col-">
                                            <Link to='/shop/tous-les-produits' className='nav-link back-to-boutique mb-3'>RETOUR À LA BOUTIQUE</Link>
                                        </div>
                                    </div>
                                </>
                                :
                                cartItems.map(item => (
                                    <SubShoppingCart key={item.ukey} variantId={item.variant} itemKey={item.ukey} {...item} />
                                ))
                            }
                        </div>
                    </div>

                    {cartQuantity > 0 && (
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="total-price text-center d-flex justify-content-between px-4">
                                    <span className="price-text mt-2">SOUS-TOTAL :</span>
                                    <span className="price-value mt-2">{total.toFixed(2)} DH</span>
                                </div>

                            </div>
                        </div>
                    )}

                </div>
                <div className="row">
                    {cartQuantity > 0 && (
                        <div className="mt-4 col-md-12 col-12 text-center card-checkout d-flex-center">
                            <div className='card-checkout-btn mb-2 nav-link ff-lato-reg clr-blue c-pointer text-cap' onClick={() => emptyShoppingCart()}>
                                <Icons.BsFillTrashFill className='card-checkout-icon' /> Vider mon panier
                            </div>
                        </div>
                    )}
                    <div className="subshopping-card-message text-center c-pointer" >
                        <Link to='/shop/my-cart ' className='nav-link clr-light'>
                            VOIR LE PANIER
                        </Link>
                    </div>
                </div>
            </div>
            {/* End shoppingCart box */}

            {/* Start Search box */}
            <SearchBox
                searchBoxVisible={searchBoxVisible}
                textValue={textValue}
                position='top'
                products={products}
                productIds={productIds}
                isProductIds={isProductIds}
                setTextValue={setTextValue}
                searchProducts={searchProducts}
            />
            {/* End Search box */}
        </div>
    )
}

export default ShopTopHeader