import { FC } from 'react'
import './ColoringLoading.css'

const ColoringLoading: FC = () => {
    return (
        <div className="loading-container">
            <div className="loading-container-loader"><span></span></div>
            <div className="loading-container-loader"><span></span></div>
            <div className="loading-container-loader"><i></i></div>
            <div className="loading-container-loader"><i></i></div>
        </div>
    )
}

export default ColoringLoading
