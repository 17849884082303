import { FC, useEffect, useState } from 'react';
import choc1 from '../../../assets/images/chocolat/Pralines-red-heart.png'
import choc2 from '../../../assets/images/chocolat/Gourmandise_0_0.png'
import SubmitButton from '../SubmitButton/SubmitButton';
import './OurChocolates.css'

const OurChocolates: FC = () => {


  return (
    <div className='container px-12rem mt-5 pt-10rem'>
      <div className="row d-flex-between-center">
        <div className="col-lg-6 col-12">
          <p className='fs-51px clr-blue d-flex-center flex-wrap '>
            <span className='w-100 fw-600 ff-ubuntu-m'>Découvrez nos</span>
            <span className='w-100 clr-yellow ff-satisfy fs-55px mt-n-17px'> chocolats</span>
          </p>

          <p className='clr-dark max-w-20rem'>
          Aussi délicieux que variés, nos chocolats font notre renommée. Qu’allez-vous savourer en premier ? Un Manon ? Un carré croquant ?
          </p>

          <p className='h-5rem d-flex-start'>
            <SubmitButton
              btnLabel='Découvrez nos chocolats'
              className='py-13px submit-btn'
            />
          </p>
        </div>

        <div className="col-lg-6 col-12">
          <div className="our-chocolates-image-1">
            <img src={choc1} alt="" className='w-100' />
          </div>
        </div>

      </div>

      <div className="row d-flex-between-center">

        <div className="col-lg-6 col-12">
          <div className="our-chocolates-image-2">
            <img src={choc2} alt="" className='w-100' />
          </div>
        </div>


        <div className="col-lg-6 col-12 ps-5rem mt-5 pt-1">
          <p className='fs-51px clr-blue d-flex-tart flexwrap  '>
            <span className='fw-600 ff-ubuntu-m'>Craquez pour nos</span>
            <span className='clr-yellow ff-satisfy fs-55px mt-n-17px'> gourmandises</span>
          </p>

          <p className='clr-dark max-w-20rem'>
          Le plaisir se décline sous toutes ses formes : mendiants, orangettes, pâtes de fruits... Préparez-vous à une explosion de saveurs !
          </p>

          <p className='h-5rem d-flex-start'>
            <SubmitButton
              btnLabel='Toutes nos gourmandises'
              className='py-13px submit-btn'
            />
          </p>
        </div>

      </div>
    </div>
  );
};

export default OurChocolates;