import { FC } from 'react'
import './ShopAboutUs.css'
import { shopAboutUsImg } from '@helpers/Index'

const ShopAboutUs: FC = () => {

    return (
        <div className='shop-about-us pxrem py-3rem'>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <h4 className='clr-blue fw-600 mb-5'>À propos de Leonidas</h4>
                        <p className='clr-blue pe-6rem'>
                            Depuis 1913, Leonidas n'a qu'une mission : rendre accessible à tous de savoureuses pralines d'une qualité incomparable. Notre chocolat belge est aujourd'hui en vente dans plus de 1 300 boutiques du monde entier. Plus de 100 variétés de pralines, toutes fabriquées à base de chocolat 100 % pur beurre de cacao. Toutes les boutiques Leonidas garantissent la finesse et la fraîcheur d'un chocolat de qualité accessible à tous. Ce n'est pas pour rien que nos pralines sont les préférées des amateurs de chocolat en Belgique.
                        </p>
                    </div>

                    <div className="col-4 text-center">
                        <p className='fw-600 clr-blue fs-18px ff-lato-reg'>Fournisseur breveté de la Cour de Belgique</p>
                        <img src={shopAboutUsImg} alt="" />
                    </div>

                    <span className='tag-hr px-3 mt-5 d-none' ></span>
                </div>
            </div>
        </div>
    )
}

export default ShopAboutUs
