import { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useShoppingCart } from '../../../contexts/ShoppingCartContext';
import { useApiRequest } from '@helpers/ApiRequest';
import { CartItemProps, VariantProps } from '@helpers/Props';
import { useShoppingCartDataContext } from '../../../contexts/ShoppingCartDataContext';
import { uploadUrl, variantIconUrl } from '@helpers/Helpers';
import Icons from '@helpers/IconsHelper';
import './ShoppingCartItem.css'

const ShoppingCartItem: React.FC<CartItemProps> = ({ product, quantity, isvariant, variantId, price, itemKey }) => {

  const {
    removeFromCart,
    incrementCartQuantity,
    decrementCartQuantity
  } = useShoppingCart()


  const { products, packs } = useShoppingCartDataContext();
  const [discount, setDiscount] = useState(null);
  const apiRequest = useApiRequest();
  const [variantPrice, setVariantPrice] = useState<number>();
  const [isVariant, setIsVariant] = useState(false);

  const mergedArray = [...products, ...packs];
  const item = mergedArray.find(i => i.itemType === 'pack' ? i.uniqId === product : i.id === product)

  const fetchPromotionData = useCallback(async () => {
    try {

      if (item?.itemType === 'product' && item?.id) {
        const response = await apiRequest({
          route: `promotion-discount/product_id/${item.id}`,
          method: 'GET',
        });

        setDiscount(response.data.discount)
      }

    } catch (error) {

    }
  }, [item?.id]);


  useEffect(() => {
    fetchPromotionData();
  }, [item?.id])

  let variantInfo: any
  if (item?.variant && item.variant.length > 0) {
    variantInfo = item.variant.find(i => i.id === variantId)
  }

  useEffect(() => {
    if (discount !== null && item?.variant && item?.variant?.length > 0) {
      let sellingPrice = variantInfo?.sellingPrice
      if (sellingPrice !== item?.sellingPrice) {
        setVariantPrice(sellingPrice - (sellingPrice * (discount / 100)))
        setIsVariant(true)
      }
    }
  }, [])

  if (item == null) return null

  let productPrice = discount !== null ? (item.sellingPrice - (item.sellingPrice * (discount / 100))) : item.sellingPrice;


  return (
    <>
      <div className="shopping-card-item mx-5px radius- shadow-2 mb-1rem d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center align-items-center shopping-card-item-content">
            <div className="col-lg-2 col-md-2 col-sm-5 col-5 d-flex align-items-center shopping-card-item-image">
              <Link to={`${item.itemType === 'pack' ? `/boutique/pack/${item.slug}` : `/produit/${item.slug}`}`} className='d-inline-flex h-100'>
                <img src={`${uploadUrl}/${item.itemType === 'pack' ? 'pack' : 'product'}_images/${item.image}`} alt={item.title} className="rounded shopping-card-item-img" />
              </Link>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-7 col-7 d-flex align-items-center">
              <div className="shopping-card-item-details">
                <div className="shopping-card-item-title text-cap">{item.title} {isvariant ? `- ${variantInfo?.size}` : null}</div>
                {/* {isvariant ?
                  <div className="d-flex ai-center">
                    <div className={`subvariant-size-content mx-1 d-flex-center c-pointer selected-border`} >
                      <div className="subvariant-icon d-flex-center">
                        <img src={`${variantIconUrl}/${variantInfo?.icon}`} alt={variantInfo?.label} className='variant-size-img' />
                      </div>
                    </div>
                    <div className="subvariant-title ff-lato-reg fw-600">
                      {`${variantInfo?.reference} ${variantInfo?.label}`}
                    </div>
                  </div>
                  :
                  null
                } */}
                <div className="shopping-card-item-prix clr-dark">
                  {/* {item.itemType === 'pack' ?
                    isvariant ?
                      variantPrice?.toFixed(2)
                      : item.sellingPrice.toFixed(2)
                    : productPrice.toFixed(2)} */}
                  {price}
                  DH</div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex align-items-center">
              <div className="shopping-card-item-quantity align-items-center text-center">
                <span className="align-items-center fs-4"
                  onClick={quantity > 1 ? () => decrementCartQuantity(item.itemType === 'pack' ? item.uniqId : item.id, itemKey) : undefined}>
                  <Icons.HiMinusSm className='shopping-card-item-quantity-icon' />
                </span>
                <span className="align-items-center px-2 shopping-card-item-quantity-value user-select-none">{quantity}</span>
                <span className="align-items-center fs-4"
                  onClick={() => incrementCartQuantity(item.itemType === 'pack' ? item.uniqId : item.id, item.itemType === 'pack' ? item.sellingPrice : productPrice, itemKey)}>
                  <Icons.BiPlus className='shopping-card-item-quantity-icon' />
                </span>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex align-items-center">
              <div className="shopping-card-item-total align-items-center">
                <span className="align-items-center user-select-none px-">{(price * quantity).toFixed(2)} DH</span>
                {/* <span className="align-items-center user-select-none px-">{item.itemType === 'pack' ? item.sellingPrice.toFixed(2) : (productPrice * quantity).toFixed(2)} DH</span> */}
              </div>
            </div>

            <div className="col-lg-1 col-md-1 col-sm-2 col-2 d-flex align-items-center">
              <div className="trash-icon">
                <Icons.BsFillTrashFill
                  className="trash"
                  onClick={(() => removeFromCart(item.itemType === 'pack' ? item.uniqId : item.id, itemKey))}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MemoizedShoppingCartItem = memo(ShoppingCartItem);

export { MemoizedShoppingCartItem as ShoppingCartItem };