import Layout from '@layouts/Layout'
import './MomentDetails.css'
import ShopHeaderContainer from '@components/Shop/components/ShopHeaderContainer/ShopHeaderContainer'
import { useParams } from 'react-router-dom'
import { allTheMomentsBg } from '@helpers/Index'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { AutresMoments, Cadeaux, GrandesOccasions, Occasions } from '../../../../data/moments'
import { MomentProps } from '@helpers/Props'
import { useEffect } from 'react'
import { handleScrollToTop } from '@helpers/Helpers'
import OrganisationDevisModal from '@components/Shop/components/OrganisationDevisModal/OrganisationDevisModal'

interface Moment {
    key: number;
    title: string;
    // ... other properties
    slug: string;
    details: {
        // ... details properties
    };
};

const MomentDetails: React.FC = () => {

    useEffect(() => {
        handleScrollToTop()
    }, [])

    const { slug } = useParams()
    const { windowWidth } = useWindowWidthContext()

    const allMoments = {
        ...GrandesOccasions.reduce((acc, moment) => ({ ...acc, [moment.slug]: moment }), {}),
        ...Occasions.reduce((acc, moment) => ({ ...acc, [moment.slug]: moment }), {}),
        ...Cadeaux.reduce((acc, moment) => ({ ...acc, [moment.slug]: moment }), {}),
        ...AutresMoments.reduce((acc, moment) => ({ ...acc, [moment.slug]: moment }), {}),
    };

    const momentSlug = Object.keys(allMoments);
    const index = momentSlug.indexOf(`${slug}`);
    // const selectedIndex = Object.keys(allMoments)[index];
    const momentAtIndex: MomentProps = Object.values(allMoments)[index] as MomentProps;

    return (
        <Layout>
            <ShopHeaderContainer
                title='Partagez le bonheur!'
                background={allTheMomentsBg}
                url=''
                haveBreadCrumbs={false}
                titleStyle={`clr-blue fs-${windowWidth > 578 ? '60' : '25'}px`}
                haveImage
                image={momentAtIndex.image}
                haveSecondUrl={false} />
            <div className='container min-h-25rem mb-4rem'>
                <div className={`row d-flex ${windowWidth > 578 ? 'min-h-20rem' : 'pt-3rem'}`}>
                    <div className="col-12">
                        <p className='max-w-38rem clr-dark ff-ubuntu-reg fs-18px'>
                            {momentAtIndex.details.firstDescription}
                        </p>
                    </div>
                </div>

                <div className={`row d-flex ${momentAtIndex.details.image === '' ? 'd-none' : ''}`}>
                    <div className="col-lg-5 col-12">
                        <div className="moment-second-image w-100">
                            <img src={momentAtIndex.details.image} alt={momentAtIndex.title} className='w-100 radius-5px' />
                        </div>
                    </div>
                    <div className="col-lg-7 col-12">
                        <p className='max-w-38rem clr-dark ff-ubuntu-reg fs-18px'>
                            {momentAtIndex.details.secondDescription}
                        </p>

                        <div className="submit-devis">

                        </div>
                    </div>
                </div>

                <div className="row mt-4rem">

                    <hr className='hr-tag' />
                    <h3 className='ff-ubuntu-m clr-orange'>Demander votre devis: </h3>
                    <div className="col-lg-6 col-12 mt-1rem">
                        <OrganisationDevisModal type={momentAtIndex.title} />
                    </div>
                </div>
            </div>



        </Layout>
    )
}

export default MomentDetails
