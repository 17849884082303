import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { facebook, formHelper, loadingHelper, pagesBg } from '@helpers/Index';
import { LoginSocialGoogle, IResolveParams, } from 'reactjs-social-login'
import { GoogleLoginButton } from 'react-social-login-buttons'
import ReactFacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import { useApiRequest } from '@helpers/ApiRequest';
import { InfoNotify, ErrorNotify, FrCustomeErrorNorify } from '@helpers/Toastify';
import { JSONHeader, REACT_APP_FB_APP_ID, REACT_APP_GG_APP_ID, ReactHelmet, apiUrl } from '@helpers/Helpers';
import { loginSchema } from '@helpers/YupHelper';
import { initLogin } from '@helpers/FormikHelper';
import Icons from '@helpers/IconsHelper';
import Layout from '@layouts/Layout';
import AuthHeader from './AuthHeader';
import './LoginStyle.css'
import ShopLayout from '@layouts/ShopLayout';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';

const Login: React.FC = () => {

  // Global
  const location = useLocation();
  const navigate = useNavigate();
  const apiRequest = useApiRequest();
  const isLogin = location.pathname === '/shop/login';
  const isRegister = location.pathname === '/shop/register';
  const leon_token = localStorage.getItem('leon_token');
  const [isLoading, setIsLoading] = useState(false);
  const { windowWidth } = useWindowWidthContext()
  const withGoogleRef = useRef<HTMLButtonElement | null>(null);
  const [isLoginWithEmail, setIsLoginWithEmail] = useState(false);
  const [isSighUp, setIsSighUp] = useState(false);


  const handleElse = () => {
    FrCustomeErrorNorify()
    setIsLoading(false)
  }
  const startWithGoogle = () => {
    if (withGoogleRef.current) {
      withGoogleRef.current.click();
    }
  };


  // Google Login
  const [provider, setGoogleProvider] = useState('')
  const [googleProfile, setGoogleProfile] = useState<any>()
  const [isLoginWithGoogle, setIsLoginWithGoogle] = useState(false);

  // Faebook Login
  const [facebookLogin, setFacebookLogin] = useState(false);
  const [facebookProfile, setFacebookProfile] = useState<ReactFacebookLoginInfo>();
  const [fbClick, setFbClick] = useState(false);
  const [isLoginWithFacebook, setIsLoginWithFacebook] = useState(false);


  useEffect(() => {
    if (leon_token) {
      navigate('/shop/my-account');
    }
  }, [leon_token]);

  const responseFacebook = (response: ReactFacebookLoginInfo) => {
    setFacebookProfile(response);
    if (response.accessToken) {
      setFacebookLogin(true);
    } else {
      setFacebookLogin(false);
    }
  };

  const handleClickFacebook = () => {
    setFbClick(true);
  };

  const formik = formHelper.useFormik({
    initialValues: initLogin,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)

        const response = await apiRequest({
          route: `login`,
          method: 'POST',
          body: values,
          headers: JSONHeader,
        })

        if (response.status === 200) {
          const { token } = response.data;
          localStorage.setItem('leon_token', token);
          setIsLoginWithEmail(true)
          setIsSighUp(false)
        } else {
          handleElse()
        }

      } catch (error) {
        ErrorNotify('Email ou mot de pass est incorrect');
        setIsLoading(false)
      }
    },
  });


  //=============================== Start withGoogle
  const withGoogle = async () => {

    if (googleProfile) {
      setIsLoading(true)

      const GPassword = `${googleProfile.email}${googleProfile.sub}`;
      const formData = new FormData();
      formData.append('email', googleProfile.email);
      formData.append('password', GPassword);
      formData.append('loginWith', 'google');

      const response = await apiRequest({
        route: `user/email/${googleProfile.email}`,
        method: 'GET',
      });

      if (response.status === 200) {
        if (response.data === 'Not Found') {
          try {
            const response = await formHelper.axios.post(`${apiUrl}/register`, formData);

            if (response.status === 200) {
              formData.delete('loginWith')

              const loginResponse = await apiRequest({
                route: `login`,
                method: 'POST',
                body: formData,
                headers: JSONHeader,
              })

              const token = loginResponse.data.token;
              localStorage.setItem('leon_token', token);
              setIsLoginWithGoogle(true);
              setIsSighUp(true)
            } else {
              handleElse()
            }

          } catch (error) {
            handleElse()
          }

        } else {
          setIsLoading(true)
          formData.delete('loginWith')

          const loginResponse = await apiRequest({
            route: `login`,
            method: 'POST',
            body: formData,
            headers: JSONHeader,
          })

          if (loginResponse.status === 200) {
            const token = loginResponse.data.token;
            localStorage.setItem('leon_token', token);
            setIsLoginWithGoogle(true);
          } else {
            handleElse()
          }
        }
      } else {
        handleElse()
      }

    } else {
      handleElse()
    }
  }

  useEffect(() => {
    if (googleProfile) {
      withGoogle();
    }
  }, [googleProfile])
  //=============================== End withGoogle


  // ============================== Start withFacebook
  const withFacebook = async () => {

    if (facebookProfile && facebookProfile.userID) {
      setIsLoading(true)

      const GPassword = `${facebookProfile.userID}@withfacebook`;
      const formData = new FormData();
      formData.append('email', facebookProfile.userID);
      formData.append('password', GPassword);
      formData.append('loginWith', 'facebook');

      const response = await apiRequest({
        route: `user/email/${facebookProfile.userID}`,
        method: 'GET',
      });

      if (response.status === 200) {
        if (response.data === 'Not Found') {
          try {
            const response = await apiRequest({
              route: `register`,
              method: 'POST',
              body: formData,
            })

            if (response.status === 200) {
              formData.delete('loginWith')

              const loginResponse = await apiRequest({
                route: `login`,
                method: 'POST',
                body: formData,
                headers: JSONHeader,
              })

              if (loginResponse.status === 200) {
                const token = loginResponse.data.token;
                localStorage.setItem('leon_token', token);
                setIsLoginWithFacebook(true);
                setIsSighUp(true)
              }

            } else {
              handleElse()
            }

          } catch (error) {
            handleElse()
          }

        } else {
          formData.delete('loginWith')
          setIsLoading(true)

          const loginResponse = await apiRequest({
            route: `login`,
            method: 'POST',
            body: formData,
            headers: JSONHeader,
          })

          const token = loginResponse.data.token;
          localStorage.setItem('leon_token', token);
          setIsLoginWithFacebook(true);
        }

      } else {
        handleElse()
      }

    }
  }

  useEffect(() => {
    if (facebookProfile && facebookProfile.userID) {
      withFacebook();

    }
  }, [facebookProfile])
  // ============================== End withFacebook


  const getToken = async () => {
    try {
      const response = await apiRequest({
        route: `me`,
        method: 'GET',
        headers: { Authorization: `Bearer ${localStorage.getItem('leon_token')}` },
      })

      if (response.status === 200) {
        localStorage.setItem('leon_user', JSON.stringify(response.data));
        isSighUp ?
          isLoginWithEmail
            ? navigate('/shop/my-account')
            :
            <>
              {navigate('/shop/my-account/edit-account')}
              {InfoNotify('Merci de compléter les details de votre compte')}
            </>
          :
          navigate('/shop/my-account')
      }

    } catch (error) {
      handleElse()
    }
  };

  if (leon_token) {
    getToken();
  }

  return (
    <ShopLayout
      containerTitle='Compte'
      containerBackground={pagesBg}
      containerUrl=''
      haveBreadCrumbs
      haveImage={false}
      haveSecondUrl={false}>
      <ReactHelmet title='Se connecter' />
      <AuthHeader isLogin={isLogin} isRegister={isRegister} />
      <div className={`d-flex-center ${windowWidth > 578 ? 'container pt-5' : 'auth-container-max-w pt-1'}`}>
        <form onSubmit={formik.handleSubmit} className='d-flex-center'>
          <div className="login-form mb-5">
            <div className="row d-flex-start">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                <formHelper.FormInput
                  label="Email"
                  spanValue="Email"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  className={formik.touched.email && formik.errors.email ? "is-invalid" : ""}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired
                />
              </div>
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <formHelper.FormInput
                  label="Mot de passe"
                  spanValue="Mot de passe"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  className={formik.touched.password && formik.errors.password ? "is-invalid" : ""}
                  touched={formik.touched}
                  errors={formik.errors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired
                />
              </div>

              <div className="row mt-3 d-flde justify-content-betweden align-items-cendter form-check-content">
                <div className="form-check col-12 ms-4 text-end">
                  <Link to='/shop/forgot-password' className='nav-link forgot-password'>MOT DE PASSE PERDU ?</Link>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-4 d-flex-start">
                {isLoading ?
                  <loadingHelper.DottedLoading />
                  :
                  <formHelper.SubmitButton
                    className="mt-1 px-5 submit-btn-1 py-13px"
                    btnLabel="Connexion"
                  />
                }

              </div>

            </div>
            <hr className='hr-tag' />

            <div className="row d-flex-center max-sm-w mt-4">

              {/* Start Google */}
              <div className="col-12 d-flex-center">

                <div className="with-google d-flex-center">
                  <div className="with-google-logo">

                    <LoginSocialGoogle
                      client_id={REACT_APP_GG_APP_ID || ''}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={async ({ provider, data }: IResolveParams) => {
                        try {
                          setGoogleProvider(provider);
                          setGoogleProfile(data);
                        } catch (error) {
                        }
                      }}
                      onReject={async (err) => {
                        try {
                        } catch (error) {
                        }
                      }}
                    >
                      <span className='bm-btn' ref={withGoogleRef}><GoogleLoginButton className='google-btn login-icon' /></span>
                    </LoginSocialGoogle>
                  </div>

                  <div className="with-google-text ff-lato-reg fw-600 c-pointer" onClick={startWithGoogle} >
                    Continue Avec Google
                  </div>
                </div>

              </div>
              {/* End Google */}

              {/* Start Separate */}
              <div className="col-12 d-flex-center with-social">
                <div className="with-social-sepetaror">
                  <p className='ff-lato-reg fw-600'> <span className='with-social-sepetaror-before'></span> OU <span className='with-social-sepetaror-after'></span></p>
                </div>
              </div>
              {/* End Separate */}


              {/* Start Facebook */}
              <div className="col-12 d-flex-center">

                <div className="with-facebook d-flex-center">
                  <div className="with-facebook-logo">
                    <img src={facebook} className='login-icon ms-1 me-2' alt="facebook" onClick={handleClickFacebook} />
                    {fbClick && !facebookLogin &&
                      <ReactFacebookLogin
                        appId={REACT_APP_FB_APP_ID}
                        autoLoad={true}
                        fields="name,email,picture"
                        scope="public_profile,user_friends"
                        callback={responseFacebook}
                        icon={<Icons.BsFacebook />} />
                    }
                  </div>

                  <div className="with-facebook-text ff-lato-reg fw-600 c-pointer" onClick={handleClickFacebook}>
                    Continue Avec Facebook
                  </div>
                </div>

              </div>
              {/* End Facebook */}


            </div>
          </div>
        </form>

      </div>
    </ShopLayout>
  );
};

export default Login;