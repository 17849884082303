import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { OrderByDevisModalProps } from '@helpers/Props'
import { FormInput, formHelper, loadingHelper, SubmitButton } from '@helpers/Index'
import { useApiRequest } from '@helpers/ApiRequest'
import { FrCustomeErrorNorify, notify } from '@helpers/Toastify'
import { initOrderByDevis } from '@helpers/FormikHelper'
import { orderByDevisSchema } from '@helpers/YupHelper'
import { FormatDate, JSONHeader, orderReferenceGenerator } from '@helpers/Helpers'
import './OrderByDevisModal.css'

const OrderByDevisModal: React.FC<OrderByDevisModalProps> = ({ show, onHide, product, variant, isvariant, color }) => {

    const apiRequest = useApiRequest()
    const [isLoading, setIsLoading] = useState(false)

    const formik = formHelper.useFormik({
        initialValues: initOrderByDevis,
        validationSchema: orderByDevisSchema,
        onSubmit: async (values) => {

            const checkResponse = await apiRequest({
                route: `last-orderbydevis`,
                method: 'GET'
            })

            if (checkResponse.status === 200) {
                setIsLoading(true)
                const reference = `${orderReferenceGenerator()}${checkResponse.data.lastOrderId + 1}`

                try {
                    const data = {
                        reference: reference,
                        fullName: values.fullName,
                        email: values.email,
                        telephone: values.telephone,
                        adresse: values.adresse,
                        ville: values.ville,
                        codepostal: Number(values.codepostal),
                        color: color ? color : null,
                        // price: price * quantity,
                        // quantity: quantity,
                        orderNote: values.orderNote,
                        orderStatus: 'pending',
                        isvariant: isvariant,
                        product: `api/products/${product.id}`,
                        variant: isvariant ? `api/variants/${variant}` : null,
                        created: FormatDate(new Date()),
                        updated: FormatDate(new Date())
                    }

                    const response = await apiRequest({
                        route: `order_by_devis`,
                        method: 'POST',
                        body: data,
                        headers: JSONHeader,
                    })
                    if (response.status === 201) {
                        onHide()
                        setIsLoading(false)
                        notify('Votre commande â été envoyé')
                    } else {
                        FrCustomeErrorNorify()
                        setIsLoading(false)
                    }

                } catch {
                    FrCustomeErrorNorify()
                    setIsLoading(false)
                }
            }
        },
    })

    return (
        <Modal show={show} onHide={onHide} centered dialogClassName='order-by-devis'>
            <Modal.Header closeButton>
                <Modal.Title className='fs-4'>Details de Commande</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <form onSubmit={formik.handleSubmit} >

                            <div className="col-12 mb-4">
                                <formHelper.FormInput
                                    label="Nom et prenom"
                                    spanValue="Nom et prenom"
                                    name="fullName"
                                    value={formik.values.fullName}
                                    className={formik.touched.fullName && formik.errors.fullName ? "is-invalid" : ""}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    isRequired
                                />
                            </div>

                            <div className="col-12 mb-4">
                                <FormInput
                                    label="Email"
                                    spanValue="Email"
                                    name="email"
                                    value={formik.values.email}
                                    className={formik.touched.email && formik.errors.email ? "is-invalid" : ""}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    isRequired
                                />
                            </div>

                            <div className="col-12 mb-4">
                                <FormInput
                                    label="Telephone"
                                    spanValue="Telephone"
                                    name="telephone"
                                    value={formik.values.telephone}
                                    className={formik.touched.telephone && formik.errors.telephone ? "is-invalid" : ""}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    isRequired
                                />
                            </div>

                            <div className="col-12 mb-4">
                                <FormInput
                                    label="Adresse"
                                    spanValue="Adresse"
                                    name="adresse"
                                    value={formik.values.adresse}
                                    className={formik.touched.adresse && formik.errors.adresse ? "is-invalid" : ""}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    isRequired
                                />
                            </div>

                            <div className="col-12 mb-4">
                                <FormInput
                                    label="Ville"
                                    spanValue="Ville"
                                    name="ville"
                                    value={formik.values.ville}
                                    className={formik.touched.ville && formik.errors.ville ? "is-invalid" : ""}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    isRequired
                                />
                            </div>

                            <div className="col-12 mb-4">
                                <FormInput
                                    label="Code Postal"
                                    spanValue="Code Postal"
                                    name="codepostal"
                                    value={formik.values.codepostal}
                                    className={formik.touched.codepostal && formik.errors.codepostal ? "is-invalid" : ""}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    isRequired
                                />
                            </div>

                            <div className="col-12 mb-4">
                                <formHelper.FormInput
                                    label="Remarque"
                                    spanValue="Remarque"
                                    name="orderNote"
                                    textarea={true}
                                    value={formik.values.orderNote}
                                    className={formik.touched.orderNote && formik.errors.orderNote ? "is-invalid" : ""}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    rows={6}
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                />
                            </div>

                            <div className="col-12 mt-5 d-flex-start">
                                {isLoading ?
                                    <loadingHelper.DottedLoading />
                                    :
                                    <SubmitButton
                                        className="px-2rem py-14px submit-btn-1"
                                        btnLabel="valider la commande"
                                    />
                                }
                            </div>
                        </form>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default OrderByDevisModal