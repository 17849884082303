import React, { ChangeEvent, FocusEvent } from 'react'
import './FormInputStyle.css'

interface FormInputProps {
    label?: React.ReactNode | string
    spanValue?: string
    name?: string
    value?: string
    className?: string
    textarea?: boolean
    rows?: number
    type?: string
    required?: boolean
    isRequired?: boolean
    touched?: any
    errors?: any
    handleChange?: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
    handleBlur?: (event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => void
    feedbackStyle?: string
}

const FormInput: React.FC<FormInputProps> = ({
    label = "",
    spanValue = "",
    name = "",
    textarea = false,
    rows = 6,
    value = "",
    className = "",
    type = "text",
    required = false,
    isRequired = false,
    touched = {},
    errors = {},
    handleChange = () => { },
    handleBlur = () => { },
    feedbackStyle = "",
}) => {
    return (
        <div className={`input-box`}>
            <label htmlFor={name} className={`form-label ${value !== '' ? 'label-display' : ''}`}>
                {label}{isRequired ? <p className='clr-red d-contents m-0 p-0'>*</p> : null}
            </label>

            {textarea ? (
                <textarea
                    name={name}
                    className={`${value ? 'input-box-input' : ''} ${className}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                    required={required}
                    rows={rows}
                />
            ) : (
                <input
                    type={type}
                    name={name}
                    className={`${value ? 'input-box-input' : ''} ${className}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                    required={required}
                />
            )}

            <span>{spanValue}</span>

            {touched[name] && errors[name] ? (
                <div className={`invalid-feedback ${feedbackStyle}`}>{errors[name]}</div>
            ) : null}
        </div>
    )
}


export default FormInput