import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { FC } from 'react';

interface HeaderContainerProps {
  title: any;
  className: string;
}

const HeaderContainer: FC<HeaderContainerProps> = ({ title, className }) => {
  const { windowWidth } = useWindowWidthContext()

  return (
    <div className="roww align-items-center">
      <div className="col-md-12 col-lg-12 mb-5 text-center justify-content-center">
        <div className="d-inline">
          <h2 className={`${windowWidth < 768 ? 'fs-1' : ''} ${className}`}>{title}</h2>
        </div>
      </div>
    </div>
  );
};

export default HeaderContainer