import { FC, useEffect, useState } from 'react';
import { useApiRequest } from '@helpers/ApiRequest';
import { ProductProps } from '@helpers/Props';
import { HeaderContainer, formHelper, hooks } from '@helpers/Index';
import { apiUrl } from '@helpers/Helpers';
import { Link } from 'react-router-dom';
import ProductSwiper from '../ProductSwiper/ProductSwiper';

const PopulareProducts: FC = () => {
  const [products, setProducts] = useState<ProductProps[]>([]);
  const { productIds, isProductIds } = hooks.useProductIds();
  const apiRequest = useApiRequest()

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'populare-products/5',
        method: 'GET',
      });

      if (response.status === 200) {
        setProducts(response.data)
      }
    } catch (error) {

    }
  };


  const fetchProductsData = async () => {
    try {
      const response = await formHelper.axios.get(`${apiUrl}/products.json`, {
        params: {
          disponibilite: 'enstock',
          bestseller: 'enable',
          page: 1,
          itemsPerPage: 8,
        },
      })

      if (response.status === 200) {
        setProducts(response.data)
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchData();
    // fetchProductsData()
  }, [])


  return (
    <>
      {products.length > 0 ?
        <div className="home-container populare-products">
          <div className="containers mx-4rem">
            <HeaderContainer className="fw-600 clr-blue mt-2 pt-5 fs-37px ff-ubuntu-m" title="Nos recommandations" />
            <div className="row justify-content-center">
              <ProductSwiper items={products} itemType='product' />
            </div>

            <div className="d-flex justify-content-center d-none">
              <Link to='/boutique/best-seller' className="btn-loading mt-2 p-0 d-flex-center" >
                Voir plus
              </Link>
            </div>
          </div>
        </div>
        :
        null
      }
    </>
  );
};

export default PopulareProducts;