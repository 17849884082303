import React, { useEffect, useState } from 'react'
import { useApiRequest } from '@helpers/ApiRequest';
import { Helmet } from 'react-helmet-async';
import AOS from 'aos';
import BlogSwiper from '@components/Carousel/BlogSwiper/BlogSwiper';
import { Footer, HomeNavbar, TopHeader } from '@helpers/Index';
import HomeCarousel from '@components/Carousel/HomeCarousel/HomeCarousel';
import 'aos/dist/aos.css';
import ChristmasSection from '@components/ChristmasSection/ChristmasSection';
import OurChocolates from '@components/OurChocolates/OurChocolates';
import ShareMoments from '@components/ShareMoments/ShareMoments';
import QualitySection from '@components/QualitySection/QualitySection';
import AboutUs from '@components/AboutUs/AboutUs';
import ShopTopHeader from '@components/Shop/components/ShopTopHeader/ShopTopHeader';
import ShopNavbar from '@components/Shop/components/ShopNavbar/ShopNavbar';
import ShopHomeCarousel from '@components/Carousel/ShopHomeCarousel/ShopHomeCarousel';
import ShopNewsEmail from '@components/Shop/components/ShopNewsEmail/ShopNewsEmail';
import ShopAboutUs from '@components/Shop/components/ShopAboutUs/ShopAboutUs';
import ShopFooter from '@components/Shop/components/ShopFooter/ShopFooter';
import HomeBanner from '@components/Shop/components/HomeBanner/HomeBanner';
import PopulareProducts from '@components/Shop/components/PopulareProducts/PopulareProducts';


interface SliderProps {
    id: number;
    title: string;
    image: string;
}

const ShopHome: React.FC = () => {
    const [sliders, setSliders] = useState<SliderProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const apiRequest = useApiRequest()

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1300);
    }, []);

    const fetchData = async () => {
        try {
            const response = await apiRequest({
                route: 'shop_sliders.json',
                method: 'GET',
            });

            setSliders(response.data)
        } catch (error) {

        }
    };
    useEffect(() => {
        fetchData();
    }, [])


    useEffect(() => {
        AOS.init();
    }, [])


    return (

        <>
            <Helmet>
                <title>Leonidas Shop | Maître chocolatier - Chocolat et pralines belges</title>
            </Helmet>

            <ShopTopHeader />
            <ShopNavbar />
            <ShopHomeCarousel items={sliders} />
            <PopulareProducts />
            <HomeBanner />

            <ShopNewsEmail />
            <ShopAboutUs />
            <ShopFooter />
        </>
    )
}

export default ShopHome;