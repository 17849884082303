import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApiRequest } from '@helpers/ApiRequest';
import { CategorieProps } from '@helpers/Props';
import { useInfiniteQuery, useQuery } from 'react-query';
import { formHelper } from '@helpers/Index';
import { apiUrl } from '@helpers/Helpers';


const CategoriesContext = createContext<{
    categories: CategorieProps[];
    isLoading: boolean;
}>({
    categories: [],
    isLoading: false
});

export const useCategoriesContext = () => useContext(CategoriesContext);

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [categories, setCategories] = useState<CategorieProps[]>([]);
    const apiRequest = useApiRequest()

    // const fetchData = async () => {
    //     try {
    //         const response = await apiRequest({
    //             route: 'categories-with-parent',
    //             method: 'GET',
    //         });

    //         setCategories(response.data)

    //     } catch (error) {
    //         console.error(error);
    //     }
    // };


    const {
        data,
        isLoading,
    } = useQuery<CategorieProps[], Error>(
        'categories-with-parent',
        async () => {
            const response = await formHelper.axios.get(`${apiUrl}/categories-with-parent`);
            setCategories(response.data)

            return response.data;
        },
    );

    // const products = data?.pages.flat() || [];






    // useEffect(() => {
    //     fetchData();
    // }, [])

    return (
        <>
            <CategoriesContext.Provider value={{ categories, isLoading }}>
                {children}
            </CategoriesContext.Provider>
        </>

    );
};
