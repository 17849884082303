import { FC } from 'react';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';
import { useLocation } from 'react-router-dom';
import { allProductBg, eGiftCardsBg } from '@helpers/Index';
import { ShopHeaderContainerProps } from '@helpers/Props';
import ShopBreadCrumbs from '../ShopBreadCrumbs/ShopBreadCrumbs';
import './ShopHeaderContainer.css'


const ShopHeaderContainer: FC<ShopHeaderContainerProps> = ({ title, className, description, background, image, url, haveSecondUrl, titleStyle, haveBreadCrumbs, haveImage }) => {
  const { windowWidth } = useWindowWidthContext()
  const path = useLocation()
  const allProductPath = '/shop/tous-les-produits'
  const giftPath = '/shop/cartes-cadeau'

  const bgImage =
    path.pathname === allProductPath
      ? allProductBg
      : path.pathname === giftPath
        ? eGiftCardsBg
        :
        background

  const containerTitle =
    path.pathname === allProductPath
      ? 'Tous les produits'
      : path.pathname === giftPath
        ? 'Le cadeau idéal'
        :
        title

  const goTo =
    path.pathname === allProductPath
      ? 'tous-les-produits'
      : path.pathname === giftPath
        ? 'carte-cadeau'
        :
        url

  return (
    <div className={`shop-header-container w-100 min-h-${windowWidth > 578 ? '26' : '11'}rem  max-h-${windowWidth > 578 ? '32' : '12'}rem mb-4rem ${className}`} style={{ backgroundImage: `url(${bgImage})` }}>
      <div className={`container h-100 pt-${windowWidth > 578 ? '10' : '3'}rem pb-${windowWidth > 578 ? '5' : '3'}`}>
        <div className="row d-flex-between ">
          <div className="col-lg-6 col-12">
            <h1 className={`fw-600 ff-ubuntu-m mb-1rem fs-${windowWidth > 578 ? '55' : '25'}px ${titleStyle}`}>{containerTitle}</h1>
            {haveBreadCrumbs ? <ShopBreadCrumbs url={goTo} haveSecondUrl={haveSecondUrl} /> : null}
          </div>
          {haveImage ?
            <div className={`col-lg-6 col-12 container-image mt-${windowWidth > 578 ? '41' : '11'}rem `}>
              <div className="container-image-parent">
                <img src={image} alt="leonidas-maroc" className={`radius-5px ${windowWidth > 578 ? 'w-97' : 'w-55'}`} />
              </div>
            </div>
            :
            null}
        </div>
      </div>
    </div>
  );
};

export default ShopHeaderContainer