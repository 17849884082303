import { FC } from 'react'
import { ShopBreadCrumbsProps } from '@helpers/Props'
import { Breadcrumbs } from '@mui/material'
import { emphasize, styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import Icons from '@helpers/IconsHelper'
import { generateSlug } from '@helpers/Helpers'
import { Link } from 'react-router-dom'

const ShopBreadCrumbs: FC<ShopBreadCrumbsProps> = ({ url, haveSecondUrl }) => {

    const StyledBreadcrumb = styled(Chip)(({ theme }) => {
        const backgroundColor =
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[800]
        return {
            backgroundColor,
            height: theme.spacing(3),
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.06),
            },
            '&:active': {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
            },
        }
    }) as typeof Chip


    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb" className=''>
            <StyledBreadcrumb
                component={Link}
                to="/shop"
                label="Accueil"
                icon={<Icons.AiFillHome fontSize="small" />}
            />
            {haveSecondUrl ?
                <StyledBreadcrumb
                    component={Link}
                    to={`/shop/${generateSlug(url)}`}
                    label={`${url}`}
                />
                :
                null}
        </Breadcrumbs>
    )
}

export default ShopBreadCrumbs
